.errorPage {
  background-repeat: no-repeat;
  background-size: 1000px 450px;
  height: 79%;
  background-position: center;
  background-color: white;
  margin: 50px;
  &__messages {
    position: absolute;
    top: 36%;
    right: 37%;
  }
  &__code {
    padding-left: 115px;
    margin-bottom: 250px;
    font-size: 90px;
  }
  &__message {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  &__message-container {
    padding-left: 100px;
    width: 700px;
  }
  &__hypothesis,
  &__conclusion {
    text-align: center;
  }
}
