.perfect-button {
  position: relative;
  margin: 0.5rem;
  padding: 0.5rem 1.125rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s, color 1ms ease-in-out;
  &.primary {
    background-color: #0073d1;
    color: #fff;
    fill: #fff;
    > .kite-icon {
      fill: #fff;
    }
    &:hover {
      background-color: #0062b2;
    }
  }
  &.link {
    padding: 0;
    color: #0073d1;
    background-color: transparent;
    fill: #0073d1;
    > .kite-icon {
      fill: #0073d1;
    }
    &:hover {
      fill: #0062b2;
      color: #0062b2;
      > .kite-icon {
        fill: #0062b2;
      }
    }
  }
  &.warning {
    background-color: #dd1c1a;
    color: #fff;
    fill: #fff;
    > .kite-icon {
      fill: #fff;
    }
    &:hover {
      background-color: #a11513;
    }
  }
  &.secondary {
    background-color: #d8dde6;
    color: #004366;
    fill: #004366;
    > .kite-icon {
      fill: #004366;
    }
    &:hover {
      background-color: #63738a;
      color: #fff;
      fill: #fff;
      > .kite-icon {
        fill: #fff;
      }
    }
  }
  &.outline {
    background-color: transparent;
    border-color: #0073d1;
    color: #0073d1;
    fill: #0073d1;
    > .kite-icon {
      fill: #0073d1;
    }
    &:hover {
      background-color: #0073d1;
      color: #fff;
      fill: #fff;
      > .kite-icon {
        fill: #fff;
      }
    }
  }
  &.warning-outline {
    background-color: transparent;
    border-color: #dd1c1a;
    color: #dd1c1a;
    fill: #dd1c1a;
    > .kite-icon {
      fill: #dd1c1a;
    }
    &:hover {
      background-color: #dd1c1a;
      color: #fff;
      fill: #fff;
      > .kite-icon {
        fill: #fff;
      }
    }
  }
  &.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    margin: 0.25rem;
    padding: 0.25rem;
    color: #000;
    background-color: rgba(0, 0, 0, 0);
    font-size: 1rem;
  }
  &.icon-warning:hover {
    color: #dd1c1a;
  }
  &.loading,
  &.success {
    display: flex;
    justify-content: center;
    align-items: center;
    > .circle-loader__wrapper {
      position: absolute;
    }
    > .button__value {
      color: rgba(0, 0, 0, 0);
      transition: color 1ms;
    }
    &:hover {
      background-color: inherit;
    }
  }
  &.success {
    background-color: #0073d1;
    &.outline {
      background-color: transparent;
      &:hover {
        color: #0073d1;
        fill: #0073d1;
        > .kite-icon {
          fill: #0073d1;
        }
      }
    }
    > .success__icon {
      position: absolute;
    }
  }
  &:focus {
    outline: none;
  }
  &.set-right {
    align-self: flex-end;
  }
  &.flex {
    display: flex;
    align-items: center;
    > .button__value.left {
      margin-left: 0.625rem;
    }
    > .button__value.right {
      margin-right: 0.625rem;
    }
  }
  &.disabled,
  &.disabled:hover {
    background-color: #e8ebf0;
    border-color: transparent;
    color: #96afc1;
    fill: #96afc1;
    > .kite-icon {
      fill: #96afc1;
    }
    cursor: not-allowed;
    pointer-events: none;
  }
}
