@import '../../styles/main';

.create-draft-btn {
  &__modal {
    min-width: 400px;
    min-height: 200px;
  }
  &__continue-btn {
    margin-left: 23%;
    margin-top: 40px;

    .kite-btn-outline-primary {
      border-color: $forest-green !important;
      color: $forest-green !important;

      &:hover {
        color: white !important;
        background-color: $forest-green !important;
        border-color: $forest-green !important;
      }
    }
  }
  &__cancel-btn {
    margin-top: 15px;
    margin-left: 38%;
  }

  &__coming-soon {
    font-style: italic;
    color: gray;
  }
}
