.kite-radio {
  display: flex;
  flex-direction: column;
  text-align: left;

  &__group-label {
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    font-weight: normal;
  }

  &__subtext {
    font-size: 0.8rem;
    color: #63738a;
    font-style: italic;
    margin-bottom: 0 !important;
  }
}

.stacked-radio {
  flex: 1;
  display: flex;
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #979797;

  &__selected {
    border-right: none;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }

    > .stacked-radio__selected-indicator {
      opacity: 1;
    }
  }

  &__selected-indicator {
    width: 5px;
    background: #0073d1;
    opacity: 0;
  }

  &__wrapper {
    flex: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
