@import './variables';

@mixin notificationIndicator() {
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 1rem;
  background-color: $froly;
  opacity: 1;
  transition: 0.5s ease-in-out;
}