.cancel-complete-modal {
  width: 569px;
  max-height: 400px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 32px;
  background-color: #fff;
  .kite-radio__button {
    margin-bottom: 0px !important;
  }
  .kite-form-group {
    margin-bottom: 0px !important;
  }
  &__header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__end-modal-btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}