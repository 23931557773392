@import '../../styles/main';

.content-maps {
  &__tab-page-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border: 1px solid $geyser;
  }
  &__page-links {
    padding-bottom: 15px;
  }

  .kite-pagination {
    width: 100%;
    text-align: left !important;
    padding-top: 15px;
    .page-actions {
      float: right;
    }
  }
}

// prevent kite pagination component from displaying redundant page number when number of pages === 1
.page-numbers {
  button[aria-label='Page 1, last page'] {
    display: none !important;
  }
}
