@import '../../styles/main';

.query-builder-rules {
  .group {
    background-color: white;
    border: 4px solid darken($geyser, 15);
  }
  .rule_group {
    background-color: #bbc9d785;
  }
  .rule-error {
    background-color: red;
  }
  .qb-lite .group--header:hover .group--header .group--drag-handler, .qb-lite .group--header:hover .group--header .group--actions, .qb-lite .group--header:not(:hover) .group--drag-handler, .qb-lite .group--header:not(:hover) .group--actions, .qb-lite .rule_group:not(:hover) .group--drag-handler, .qb-lite .rule_group:not(:hover) .group--actions {
    opacity: unset;
  }
}

.query-builder-rules-error-state {
  .group {
    background-color: white;
    border: 4px solid #d6312b;
  }
  .rule_group {
    background-color: #bbc9d785;
  }
  .qb-lite .group--header:hover .group--header .group--drag-handler, .qb-lite .group--header:hover .group--header .group--actions, .qb-lite .group--header:not(:hover) .group--drag-handler, .qb-lite .group--header:not(:hover) .group--actions, .qb-lite .rule_group:not(:hover) .group--drag-handler, .qb-lite .rule_group:not(:hover) .group--actions {
    opacity: unset;
  }
  &__error-message {
    color: #d6312b;
    padding-left: 20px;
  }
}
