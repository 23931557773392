.subscriptions {
  display: flex;
  flex-direction: column;
  &__environment-select {
    align-self: flex-end;
    margin-bottom: 1rem;
  }
  &__form-container {
    position: relative;
    padding: 2rem 3rem;
    background-color: #fff;
    border: 1px solid #d8dde6;
    border-radius: 0.25rem;
  }
  &__container {
    position: relative;
  }
  &__sub-title {
    margin-bottom: 1rem;
    font-size: 1.125rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
  }
  &__form-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
  }
  &__topic-select {
    flex: 1;
    margin-right: 0.5rem;
    margin-bottom: 0 !important;
  }
  &__endpoint-input {
    flex: 1;
    margin: 0 0.5rem;
    margin-bottom: 0 !important;
  }
  &__submit-button {
    margin: 0 0.5rem;
  }
  &__message {
    font-size: 0.8rem;
    &-error {
      color: red;
    }
    &-success {
      color: #00bf1f;
    }
  }
}
