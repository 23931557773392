.circle-loader__wrapper {
  position: relative;
  max-width: 500px;
  max-height: 500px;
}

.circle-loader__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.circle-loader {
  animation: rotate 1.5s linear infinite;
  transform-origin: center center;
}

.circle-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  &.primary {
    stroke: #0073d1;
  }
  &.light {
    stroke: #fff;
  }
  &.dark {
    stroke: #004366;
  }
  &.soft {
    stroke: rgba(255, 255, 255, 0.5);
  }
  &.warning {
    stroke: #dd1c1a;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
