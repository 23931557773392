.list__container {
  height: 100%;
  font-family: Rutledge;
  font-weight: bold;
  font-size: 1rem;

  ul {
    padding-left: 0 !important;
  }

  .selected {
    background-color: #fff;
    color: #000;
  }

  &.primary {
    background-color: #f8f8f8;
    color: #63738a;
  }

  &.secondary {
    background-color: #f8f8f8;
    color: #004366;
  }
  &.blue {
    background-color: #f8f8f8;
    color: #0073d1;
  }

  .item-count__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 0.75rem;
    padding: 1rem;
    border-radius: 100%;
    color: #63738a;
    background-color: #d8dde6;
    transition: all 0.2s ease-in-out;

    &.selected-number {
      background-color: #0073d1;
      color: #fff;
    }
  }
}

.list-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;
  padding: 1.25rem 2rem;
  padding-left: 2rem !important;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:focus {
    outline: none !important;
  }
  &:hover,
  .selected {
    box-shadow: inset 5px 0 rgba(94, 171, 216, 0.74);
  }
  &.selected {
    box-shadow: inset 5px 0 #0073d1;
  }
  &.no-selection:hover {
    box-shadow: none;
  }
}
