.orm {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);

  &__exit-back {
    margin-bottom: 10px;
  }

  .kite-alert {
    margin-top: 2rem;
    margin-bottom: 0.5rem !important;
  }

  .kite-input {
    max-width: 500px;
  }

  * .rkp-table__cell.Type {
    text-transform: capitalize;
  }

  &__left {
    justify-self: flex-start;
  }

  &__bigger-icon {
    .kite-icon {
      height: 1.4rem !important;
      width: 1.4rem !important;
    }
  }
}

.orm-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows:
    repeat(2, minmax(min-content, max-content))
    36px repeat(2, minmax(min-content, max-content));

  .rkp-table__row-exp-content {
    overflow: visible;
  }

  &__quick-view {
    height: 216px;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    grid-template-rows: 1fr;
    &-section {
      padding: 10px;
    }

    &-exps {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow: scroll;
    }

    &-divider {
      border: solid 1px #d8dde6;
      align-self: center;
      height: 100%;
    }
    .kite-alert {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    .rkp-multi-search {
      z-index: 2000;
    }

    &-groups {
      display: flex;
      flex-direction: column;
    }
  }

  &__search-grid {
    display: grid;
    grid-template-columns: 350px 12px 1fr 1fr 180px 12px 210px;
    grid-template-rows: 1fr;
  }

  &__edit-controls {
    display: flex;
  }

  .rkp-table__cell:first-child {
    font-weight: 500;
  }

  &__table-overrides .rkp-table__cell:nth-last-child(2) {
    padding-right: 0px !important;
    justify-content: flex-end;
    color: #0073d1;
  }

  .rkp-table__cell {
    padding-right: 5px !important;
  }

  .rkp-table__row-title {
    grid-gap: 2px;
  }

  .rkp-table__cell:last-child {
    color: #0073d1;
  }

  &__table-groups .rkp-table__cell:last-child {
    justify-content: flex-end;
    margin-right: 20px;
  }

  &__table-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #d8dde6;
    background-color: #ffffff;
    margin-bottom: 1px;
    height: 52px;

    div:first-child {
      justify-self: flex-start;
    }
    div:last-child {
      align-self: center;
      padding-right: 20px;
      justify-self: flex-end;
    }
  }

  .rkp-table__headings-wrapper {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-top: none;
  }
}

.orm-filter__container {
  position: relative;
  color: #2f313a;
}

.orm-filter {
  position: absolute;
  top: 44px;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-template-rows: repeat(3, 40px) 1fr 40px;
  z-index: 3000;

  font-weight: normal;
  min-width: 41rem;
  height: 33rem;
  border-radius: 2px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  text-transform: capitalize;
  padding: 20px;

  .kite-form-group {
    height: 32px;
    margin-bottom: 15px;
  }

  &__close-button {
    justify-self: flex-end;
  }

  &__products {
    display: flex;
    overflow: hidden;

    &-header {
      color: #63738a;
      font-size: 14px;
    }

    &-col-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: hidden;
    }

    &-col {
      flex: 1;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
