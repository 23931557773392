.experiment-roles {
  margin-bottom: 64px;

  .autosuggest {
    min-width: 18rem;
  }

  h4 {
    margin-bottom: 24px !important;
  }

  .kite-font-icon.help {
    position: relative;
    left: 105px;
    top: 25px;

    &.product-owner {
      left: 118px;
    }

    &.technical-contact {
      left: 135px;
    }

    &.administrator {
      left: 110px;
    }
  }

  .role-multiple {
    display: flex;
    flex-direction: column;

    .role-multiple__pills {
      display: flex;
      flex-wrap: wrap;
      max-width: 688px;
    }
  }
}
