.or-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20px 20px 44px 20px 1fr 40px minmax(
      min-content,
      max-content
    );
  grid-column-gap: 32px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    color: #001019;
  }

  &__main {
    min-height: 300px;
    display: grid;
    grid-template-columns: 1fr 180px;
    grid-auto-rows: 90px;
  }

  &__detail-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__detail {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 40px;
  }

  &__delete-container {
    display: flex;
    justify-content: flex-end;
  }

  &__delete {
    margin-top: 10px;
    justify-self: flex-end;
    .kite-btn-outline-primary {
      border-color: #d6312b;
      color: #d6312b;
      width: 210px !important;
    }

    .kite-btn-outline-primary:hover,
    .kite-btn-outline-primary:focus {
      background-color: #d6312b;
    }
  }

  &__guid-lookup {
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    align-self: center;
    position: relative;
    bottom: 10px;

    &-success {
      font-weight: 500;
      display: flex;
      align-content: center;
      .kite-icon {
        margin-right: 3px;
        color: green;
      }
    }

    &-failed {
      font-weight: 500;
      display: flex;
      align-content: center;
      .kite-icon {
        margin-right: 3px;
        color: red;
      }
    }

    div:nth-child(2) {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #0062b2;
      margin-right: 40px;
    }
  }

  &__exit {
    cursor: pointer;
    color: #0062b2;
    margin-left: 40px;
  }

  .kite-input {
    max-width: 500px;
  }

  &__bigger-icon {
    .kite-icon {
      height: 1.4rem !important;
      width: 1.4rem !important;
    }
  }
}

.lookup-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 400px;
  padding: 20px;
  border-radius: 0.25rem;
  background: #fff;

  &__title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 45px;
  }

  &__controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
