.expansion-container {
  margin: 0;
  margin-bottom: 0;
  background-color: #fff;
  box-shadow: rgba(112, 130, 156, 0.2) 0px 2px 4px 0px;
  overflow: hidden;
  transition: margin-bottom 0.2s ease-in-out;
  &.hide {
    max-height: 0px;
  }
  &.no-title {
    max-height: 0;
  }
  &.show {
    max-height: 20000px;
    margin-bottom: 1rem;
  }
  &.minimal {
    max-height: unset;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
  &.light {
    max-height: unset;
    box-shadow: none;
    margin-bottom: 0;
  }
  .sub-title {
    margin: 1.5rem 0;
    font-weight: 600;
  }
  &:first-of-type .light > .expansion-icon {
    margin-top: -1rem;
  }
  &:first-of-type .light > .sub-title {
    margin-top: 0;
  }
}

.expansion-header {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0 -1px 18px 2px rgba(99, 115, 138, 0.1);
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.show {
    border-bottom: solid 1px #d8dde6;
  }
  &.small-title > .sub-title {
    font-size: 1rem;
  }
  &.light {
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-bottom: none;
    box-shadow: none;
    > .sub-title {
      margin: 1rem 0.25rem 1rem 0;
      color: #000;
      font-size: 1rem;
    }
    > .expansion-icon {
      color: #63738a;
    }
  }
}

.expansion-icon {
  width: fit-content;
  height: fit-content;
  transition: 0.3s ease-in-out;
  &.show {
    transform: rotateX(180deg);
  }
}

.expansion-child-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 20000px;
  opacity: 1;
  transition: border-top 0.3s 0.4s, border-bottom 0.3s 0.4s, opacity 0.3s 0.4s,
    max-height 0.6s ease-in-out;
  &.show-children {
    padding: 1.5rem;
    & * {
      max-height: 20000px;
      visibility: visible;
      transition: all 0.2s, visibility 0.3s 0.4s, padding 0.3s, margin 0.3s,
        max-height 0.6s ease-in-out;
    }
  }
  &.light {
    padding: 0 0.25rem;
  }
  &.hide-children {
    max-height: 0px;
    padding: 0;
    opacity: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition: padding 0.3s 0.3s, border-top 0.3s, border-bottom 0.3s,
      opacity 0.3s, max-height 0.6s 0.1s ease-in-out;
    & * {
      max-height: 0px;
      padding: 0 !important;
      margin: 0 !important;
      visibility: hidden;
      transition: visibility 0.3s, padding 0.3s 0.4s, margin 0.3s 0.4s,
        max-height 0.6s 0.1s ease-in-out;
    }
  }
  &.minimal {
    padding: 0;
    border-bottom: none;
  }
  &.primary {
    background-color: #fff;
  }
  &.nested {
    background-color: #f9f9f9;
  }
}
