@import '../../styles/main';

.evaluation-card {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__experiment-link {
    display: flex;
    h3 {
      padding-right: 15px;
    }
  }
  &__experiment-progress-save-btn {
    display: flex;
    padding-top: 5px;
  }
  &__save-btn {
    margin-left: 20px;
  }
  &__accent-color-container {
    // box-shadow: 0px 5px 5px 0px;
    position: relative;
    display: flex;
    width: 100%;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
  &__accent-color {
    max-width: 76rem;
    position: relative;
    top: 0.5rem;
    height: 100%;
    background-color: $cerulean;
    border-radius: 0.25rem 0.25rem 0 0;
    z-index: 1;
  }
  &__no-accent-color {
    position: relative;
    top: 0.5rem;
    height: 100%;
    z-index: 1;
  }
  &__accent-color-children {
    position: relative;
    margin-top: 8px;
    height: 100%;
    background-color: #fff;
  }

  &__border-rounded {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  &__steps-tabs {
    justify-content: center !important;
    padding-top: 25px;
    border: 1px solid $geyser;
    border-bottom: 0.5px solid darken($geyser, 15);
  }
  &__score-view {
    display: flex;
    justify-content: space-evenly;
    padding: 40px;
    border-right: 1px solid $geyser;
    border-left: 1px solid $geyser;
  }
  &__estimate-badge-wrap {
    padding: 1rem;
    position: relative;
    background: linear-gradient(to bottom, lighten($spectrum, 30%), $spectrum);
    border-radius: 5px;
    padding: 3px;
    margin: 15px;
  }
  &__estimate-badge {
    display: flex;
    background: white;
    color: black;
    padding: 20px;
    border-radius: 5px;
    height: 100%;
  }
  &__section-total {
    padding-right: 40px;
  }
  &__score {
    font-weight: 500;
  }

  &__complete-not-evaluating-modal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 44rem;
    padding: 1rem;
    border-radius: 0.25rem;
    padding-bottom: 2rem;
    background: #fff;
    &__close-btn {
      width: 100%;
      text-align: right;
      padding-bottom: 10px;
    }
    &__title {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      text-align: center;
    }
    &__button-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .kite-btn-primary {
      margin-bottom: 10px;
    }

    .rkp-textarea {
      padding: 0 2rem 0 2rem;
    }
  }
}
