.review {
  &__map-info-container {
    display: flex;
    width: 100%;
    padding-bottom: 40px;
  }
  &__map-info-left {
    width: 50%;
    padding-right: 10px;
  }
  &__map-info-right {
    width: 50%;
    padding-left: 10px;
  }
  &__content-map-info,
  &__personalized-content-info {
    padding-left: 20px;
    padding-bottom: 20px;
    max-width: 300px;
    overflow-wrap: anywhere;
  }
  &__personalized-content-preview-link-form {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
  }
  &__personalized-content-preview-link {
    width: 70%;
    height: fit-content;
    resize: none;
  }
  &__personalized-content-header {
    &:nth-child(2) {
      padding-left: 0;
    }
    padding-left: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    max-width: 300px;
    overflow-wrap: anywhere;
  }
  &__personalized-content-container-header,
  &__personalized-content-container-first {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
    a {
      justify-content: right;
      margin-right: 20px;
    }
  }
  &__personalized-content-container-first {
    border-top: 1px solid #979797bb;
    padding-bottom: 55px;
  }
  &__content-map-info-container {
    display: flex;
    justify-content: space-between;
  }
  &__content-map-info-container-right {
    padding-right: 35px;
  }
  &__content-map-info-container-left {
    max-width: 50%;
  }
  &__status-container {
    display: grid;
  }
  &__kite-card-info {
    min-height: 95%;
  }
  &__no-change-date {
    .rkp-datepicker__input-wrapper {
      .rkp-datepicker__icon-wrapper {
        .rkp-datepicker__icon--calendar {
          display: none !important;
        }
      }
    }
  }
  &__personalized-content-container {
    &:first-child {
      border-top: none;
    }
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 55px;
    border-top: dashed 1px #979797bb;
    border-width: 2px;
    padding-top: 20px;
    a {
      justify-content: right;
      margin-right: 20px;
    }
  }
  &__personalized-default-content-container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 55px;
    border-top: solid 4px #d8d8d8;
    padding-top: 20px;
    a {
      justify-content: right;
      margin-right: 20px;
    }
  }
  &__content-name-container {
    display: flex;
  }
  &__btn-container {
    padding-top: 44px;
    display: flex;
    justify-content: space-between;
  }
  &__exit-btn {
    margin-left: 64px;
    margin-top: 10px;
  }
  &__approval-btns-container {
    position: absolute;
    bottom: 0;
    padding-bottom: 35px;
    width: 85%;
    display: flex;
    justify-content: space-between;
  }
  &__personalized-experiment-btn {
    justify-content: right;
    margin-right: 20px;
  }
}
