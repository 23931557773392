.filter__container {
  position: relative;
  color: #2f313a;
}

.filter-controls {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  min-width: 41rem;
  height: 30rem;
  border-radius: 2px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  text-transform: capitalize;
  z-index: 200;
  &__header {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #d8dde6;
    font-weight: 500;
    > .expansion-container {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 1rem;
    font-weight: 500;
  }
  &__content {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  &__filter-scroll-box {
    flex: 1;
    overflow-y: scroll;
  }
  &__filter-selection {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.25rem 2rem;
    &.expansion {
      display: initial;
    }
    > button {
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      margin: 0 0.5rem;
      z-index: 1;
    }
    button ~ .checkbox__container,
    button ~ .select {
      padding-top: 0;
      .select__input {
        width: 22rem;
      }
    }
    .rkp-filter-search {
      order: -2;
    }
    .priority {
      order: -1;
      margin-bottom: 1rem;
      padding-bottom: 0.75rem;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -0.5px;
        width: 19rem;
        border-bottom: 1px solid #d8dde6;
      }
    }
    * .expansion-container {
      outline: none;
    }
    * .expansion-header {
      outline: none;
    }
  }
  &__checkbox-container {
    padding: 0 0.25rem;
  }
  &__empty-state {
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-top: 2.25rem;
    border-radius: 0.25rem;
    background-color: #f7f7f7;
  }
  &__footer {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d8dde6;
    padding: 1rem;
  }
}
