@import '../../styles/main';

.experiment-evaluation {
  &__accent-color-container {
    position: relative;
    display: flex;
    width: 100%;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
  &__accent-color {
    position: relative;
    top: 0.5rem;
    height: 100%;
    background-color: $cerulean;
    border-radius: 0.25rem 0.25rem 0 0;
    z-index: 1;
  }
  &__no-accent-color {
    position: relative;
    top: 0.5rem;
    height: 100%;
    z-index: 1;
  }
  &__accent-color-children {
    position: relative;
    margin-top: 8px;
    height: 100%;
    background-color: #fff;
  }

  &__border-rounded {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  &__evaluation-tabs {
    justify-content: center !important;
    padding-top: 10px;
    border: 1px solid $geyser;
    border-bottom: 0.5px solid darken($geyser, 15);
  }

  .rkp-table__headings-wrapper {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}