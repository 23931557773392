.select {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  &:first-child {
    padding-top: 0;
  }
  &.horizontal {
    padding-top: 1.25em;
  }
  &__label {
    color: #000;
    text-transform: capitalize;
  }
  &__error-message {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #b93e39;
  }
  > .expansion-container {
    margin-bottom: 0;
  }
  &__input {
    flex: 1;
    padding: 0.75rem 0.5rem;
    border: 1px solid #63738a;
    border-radius: 0;
    box-sizing: inherit;
    background: no-repeat right 1% top 50%
      url('./baseline-arrow_drop_down-24px.svg');
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    &.no-selection {
      color: #63738a;
    }
    &.warning {
      border-color: #d6312b;
    }
    &:focus {
      border-color: #0062b2;
      border-width: 2px;
      outline: none;
      box-sizing: inherit;
    }
    &:disabled {
      color: #96afc1;
      background-color: #e8ebf0;
      border-color: transparent;
    }
  }
}
