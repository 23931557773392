@import '../../styles/main';

.sample-calculator {
  &__accent-color-container {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 80%;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
  &__accent-color {
    position: relative;
    top: 0.5rem;
    height: 100%;
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: $cerulean;
    z-index: 1;
  }
  &__accent-color-children {
    position: relative;
    margin-top: 8px;
    height: 100%;
    padding: 1rem;
    border: 1px solid $geyser;
    background-color: #fff;
  }
  &__product-container {
    display: flex;
    padding-left: 30px;
    padding-top: 20px;
  }
  &__product {
    margin-right: 20px;
  }
  &__information-container {
    display: flex;
  }
  &__product-population {
    padding-left: 35px;
    padding-bottom: 30px;
    margin-right: 200px;
  }
  &__metric-mde-container {
    display: flex;
    width: 100%;
    padding-left: 30px;
  }
  &__metric {
    margin-right: 20px;
  }
  &__metric-score {
    width: 300px;
    padding-left: 10px;
  }
  &__mde {
    width: 100%;
    margin-right: 20px;
  }
  &__total-sampling {
    padding-top: 4px;
  }
  &__variant-amount {
    padding-top: 4px;
  }
  &__sampling-variant-container {
    display: flex;
    padding-left: 30px;
    margin-bottom: 30px;
  }
  &__btn-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  &__estimate-btn {
    margin-left: 25px;
    color: $forest-green !important;
    border-color: $forest-green !important;

    .kite-btn-outline-primary {
      border-color: $forest-green !important;
      color: $forest-green !important;

      &:hover {
        color: white !important;
        background-color: $forest-green !important;
        border-color: $forest-green !important;
      }
    }
  }
  &__estimate-container {
    margin-top: 50px;
  }
  &__foot-note {
    display: flex;
    justify-content: center;
    font-size: 13px;
    text-align: center;
    margin-bottom: 50px;
  }
  &__estimate-results {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  &__estimate-badge-wrap {
    max-width: 250px;
    padding: 1rem;
    position: relative;
    background: linear-gradient(to bottom, lighten($spectrum, 30%), $spectrum);
    border-radius: 5px;
    padding: 3px;
    margin: 15px;
  }
  &__estimate-warn-badge-wrap {
    max-width: 450px;
    padding: 1rem;
    position: relative;
    background: linear-gradient(to bottom, lighten(red, 30%), red);
    border-radius: 5px;
    padding: 3px;
    margin: 15px;
  }
  &__estimate-badge {
    background: white;
    color: black;
    padding: 20px;
    border-radius: 5px;
    height: 100%;
  }
  &__number {
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: $spectrum;
  }
  &__warn-number {
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: red;
  }
  &__description {
    text-align: center;
    text-align: center;
    width: 130px;
  }
  &__description-traffic {
    text-align: center;
    text-align: center;
  }
}
