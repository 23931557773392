.checkbox__container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  &.has-left-label {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  &.tight {
    padding: 6px 0.25rem;
  }
  + .checkbox__container {
    padding-top: 0;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.checkbox__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border-radius: 0.0625rem;
  box-shadow: 0 0 0 2px #63738a;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  &.checked {
    background-color: #0062b2;
    box-shadow: 0 0 0 2px #0062b2;
  }
  &.disabled {
    background-color: #63738a;
    box-shadow: 0 0 0 2px #63738a;
    pointer-events: none;
  }
}

.checkbox__indicator-icon {
  color: rgba(0, 0, 0, 0);
  font-size: 1rem;
  transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  &.checked {
    color: #fff;
  }
}

.checkbox__label {
  margin-left: 0.5rem;
  &.left-label {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  &.all {
    font-weight: 700;
  }
}
