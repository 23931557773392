.colored-select {
  position: relative;
  display: flex;
  text-transform: capitalize;
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10rem;
    min-width: 9.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
  }
  &__input-icon {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid #fff;
  }
  &__value {
    margin-right: 1.5rem;
    color: #fff;
    font-size: 0.875rem;
  }
  &__options {
    position: absolute;
    top: 2.5rem;
    display: flex;
    flex-direction: column;
    min-width: 9.5rem;
    border-radius: 0.125rem;
    box-shadow: 0 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.24),
      0 0 0.5rem 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;
    z-index: 3;
  }
  &__option {
    padding: 0.375rem 0 0.375rem 1.25rem;
    background-color: #fff;
    color: #2f313a;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    &:hover {
      background-color: #f2f2f2;
    }
    &:active {
      background-color: #dcdcdc;
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
}

// &:disabled {
//   color: #96afc1;
//   background-color: #e8ebf0;
//   border-color: transparent;
// }
