@import '../../../../styles/main';

.plan {
  &__header {
    display: flex;
  }

  h3 {
    overflow: hidden;
    font-weight: 500;
  }

  h3:after {
    background-color: $dusty-gray;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 80%;
  }

  h3:after {
    left: 0.5em;
    margin-right: -50%;
  }

  &__product-type-container {
    display: flex;
    > :first-child {
      padding-right: 50px;
    }
    :first-child {
      margin-bottom: 5px !important;
    }
  }

  &__name {
    margin-top: 20px;
  }

  &__length-sample-container {
    display: flex;
    > :first-child {
      padding-right: 20px;
    }
  }

  &__length-input-wrapper {
    > .kite-input {
      position: absolute;
      top: 1.25rem;
      left: 7.25rem;
      width: 3.75rem;
    }
    > .kite-form-group {
      margin-bottom: 0;
    }
    > label {
      max-width: 20rem;
    }
  }

  &__length-input {
    width: 42px;
  }

  &__length-container {
    width: 300px;
  }

  &__target-sample-container {
    display: flex;
  }

  &__calculator-link-container {
    padding-left: 30px;
    padding-top: 20px;
  }

  &__roles-container {
    display: flex;
    flex-wrap: wrap;
    height: 350px;
    width: 750px;
  }

  &__roles-input {
    width: 50%;
    margin-right: 60px;
  }

  .role-multiple {
    order: 1;
    display: flex;
    flex-grow: 1;
  }

  .role-multiple__pills {
    padding-top: 30px;
  }
}
