.subscription-details__container {
  position: relative;
  display: flex;
  flex-direction: column;
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.label {
  color: #61738a;
  font-size: 0.75rem;
  text-transform: capitalize;
}