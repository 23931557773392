.list-builder {
  &__container{
    display: flex;
  }
  &__controls{
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    padding: 4rem 0;
    > .kite-btn:hover {
      * .ki {
        fill: #ffffff;
      }
    }
  }
  &__control {
    margin: 1.5rem !important;
  }
  &__save-container {
    display: flex;
    margin-top: 2rem;

    .error {
      color: #d6312b;
    }

    .success {
      display: grid;
      align-items: center;
      justify-content: center;
      margin-left: 1rem;

      animation: success 0.5s 1;
      -webkit-animation: success 0.5s 1;
      animation-fill-mode: forwards;

      animation-delay: 3s;
      -webkit-animation-delay: 3s;
      -webkit-animation-fill-mode: forwards;
    }
  }
  &__save-status {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    font-size: 1.25rem;
    text-transform: capitalize;

    animation: list-builder__save-status 0.5s 1;
    -webkit-animation: list-builder__save-status 0.5s 1;
    animation-fill-mode: forwards;

    animation-delay: 3s;
    -webkit-animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
  }
  .kite-btn-sm{
    min-width: 4.075rem !important;
  }

  @keyframes success {
    from {opacity : 1;}
    to {opacity : 0;}
  }

  @-webkit-keyframes success {
    from {opacity : 1;}
    to {opacity : 0;}
  }
}
