.notification-preferences {
  &__environment-select-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  &__environment-wrapper {
    display: flex;
  }
  &__environment-label {
    margin-right: 1.25rem;
    margin-bottom: 0;
    color: #63738a;
    font-size: 1rem;
    font-weight: 500;
  }
  &__environment-select {
    margin-right: 0.75rem;
  }
  &__selections {
    position: relative;
    padding: 2rem 0;
    background-color: #fff;
    border: 1px solid #d8dde6;
    border-radius: 0.25rem;
    > .notification-preferences__clear-all {
      position: absolute;
      right: 3rem;
    }
  }
  &__accent-color {
    position: relative;
    top: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem 0.25rem 0 0;
    z-index: 1;
  }
  &__heading-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.25rem;
    > .notification-preferences__selection-heading {
      margin-bottom: 0;
    }
  }
  &__selection-heading {
    margin-bottom: 1.25rem;
    color: #2f313a;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
  &__platform-type {
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    color: #000;
  }
  &__option-container {
    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }
  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    > .kite-radio {
      margin-bottom: 0;
    }
    * .kite-custom-radio:last-of-type {
      margin-right: 0 !important;
    }
  }
  &__option-title {
    font-size: 1rem;
    font-weight: normal;
  }
  &__option-checkbox-container {
    > :first-child {
      margin-right: 2rem;
      margin-bottom: 0;
    }
    > :last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  &__platform,
  &__event {
    padding: 0 3rem;
  }
  &__event {
    padding-top: 2rem;
    border-top: 1px solid #d8dde6;
  }
  &__controls {
    display: flex;
    margin-top: 3.5rem;
    padding-left: 3rem;
    > :first-child {
      margin-right: 1rem !important;
    }
  }
  &__environment-loader-container {
    position: absolute;
    top: -1px;
    right: -1px;
    left: -1px;
    bottom: -1px;
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 5;
  }
}
