.labeled-input {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  &:first-child {
    padding-top: 0;
  }

  + .labeled-input {
    padding-top: 0;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    color: #000;
    text-transform: capitalize;
  }

  &__input-field {
    max-width: unset !important;
    margin-bottom: 0 !important;
  }

  &__error-message {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #b93e39;
  }
  > .expansion-container {
    margin-bottom: 0;
  }
}
