.navigation-container {
  position: relative;
  display: flex;
  flex-direction: flex-row;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 1280px;
  height: 100%;
  color: #000;
  background-color: #fff;
  font-family: Rutledge;
  .navigation-content__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    background-color: rgb(248, 248, 248);
    overflow: hidden;
  }
  .navigation-content__children-wrapper {
    flex: 1;
    padding: 3rem 2.5rem;
    overflow-y: scroll;
  }
  .navigation-content__alert {
    .kite-alert__global {
      justify-content: left;
    }
    .kite-alert__inner {
      max-width: 100%;
    }
  }
  .navigation-content__children-wrapper-fill {
    padding: 0;
  }
  .distillery-support-container{
    .distillery-support-button{
      border: 2px solid transparent;
      border-radius: 0.25rem;
      position: absolute;
      right: 20px;
      bottom: 10px;
      .compose-icon{
        margin-right: 10px;
      }
    }

  }
}
