.activation__container {
  display: flex;
  flex-direction: row wrap;
  align-content: space-between;
}

.activation {
  margin-top: 10px;
  margin-bottom: 30px;
  &__card {
    overflow-y: scroll;
    height: 535px;
  }
  &__header {
    display: flex;
    align-items: left;
    h3 {
      margin-bottom: 0;
      margin-right: 10px;
    }
    margin-bottom: 20px;
  }
  &__card-header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #d8dde6;
    font-size: 16px;
    font-weight: bold;
  }
  &__card-content {
    padding: 1rem;
    padding-bottom: 0;
  }
  &__none-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    font-size: 24px;
    color: #5d5d5d;
    letter-spacing: -0.5px;
  }
  &__error-message {
    color: red;
    margin-bottom: 1.5rem !important;
  }
  &__description {
    display: flex;
    width: 200%;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  &__radio-buttons {
    display: flex;
  }
  > .kite-card {
    margin-bottom: 0.5rem;
    padding: 0 !important;
  }
}

.details-link {
  color: #0062b2;
  cursor: pointer;
}

.details-link:hover {
  text-decoration: underline;
}

.event-details {
  display: flex;
  margin-left: 20px;
  margin-top: 62.5px;
  margin-bottom: 16px;
  &__card-header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #d8dde6;
    font-size: 16px;
    font-weight: bold;
  }
  &__content-empty {
    font-size: 16;
    color: #6b7f96;
    margin: 40px 44px 0 45px;
  }
  &__property-name {
    float: left;
    margin-right: 10px;
  }
  &__subproperty-name {
    margin: 10px 0px 0px 42px;
    color: #63738a;
  }
  &__property {
    display: flex;
    margin: 24px 100px 24px 50px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }
  &__subproperty {
    display: flex;
    margin: 10px 0px 20px 80px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }
  &__card {
    width: 650px;
    height: 535px;
    overflow-y: scroll;
  }
}

.details-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 28px;
}
