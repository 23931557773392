.list-box{
  width: 100%;
  min-width: 22rem;
  font-family: "Rutledge", sans-serif;
  &__title{
    display: flex;
    font-weight: 500;
    font-size: 1.25rem;
  }
  &__values-container{
    display: block;
    height: 28rem;
    min-height: 28rem;
    border: 0.0625rem solid #d8dde6;
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: #f8f8f8;
    font-weight: normal;
    overflow-y: auto;
    &::-webkit-scrollbar{
      -webkit-appearance: none;
      width: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.125rem solid #ffffff;
      border-radius: 1rem;
      background-color: #0073d1;
    }
  }
  &__empty-state{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40%;
    color: #96afc1;
    text-align: center;
  }
  &__values{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 3rem;
    padding: 1rem;
    border-bottom: #d8dde6 solid 0.0625rem;
    border-left: #f8f8f8 solid 0.125rem;
    font-size: 1rem;
    text-transform: capitalize;
    &:hover{
      border-left: 0.125rem solid #0073d1;
      background-color: #ebecee;
    }
    &.selected{
      border-left: #ebecee solid 0.125rem;
      color: #0073d1;
      background-color: rgba(0, 115, 209, 0.1);
      font-weight: 500;
    }
  }
  .rkp-filter-search {
    margin-bottom: 0;
    .rkp-filter-search__input {
      border-top: 0.0625rem solid #d8dde6;
      border-right: 0.0625rem solid #d8dde6;
      border-left: 0.0625rem solid #d8dde6;
      border-bottom: none;
      border-radius: 0.25rem 0.25rem 0 0;
    }
  }
}
