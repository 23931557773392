.content-map {
  &__content-container {
    display: flex;
    height: 300px;
    margin: 20px 0;
  }
  &__radio-content {
    margin-right: 30px;
  }
  &__side-summary {
    width: 350px;
    padding: 0 40px;
  }
  &__audience-headers {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  &__audience-question {
    font-style: italic;
    padding-right: 10px;
  }
  &__rule-builder-container {
    padding: 10px;
  }
  &__audience-quesiton-container {
    display: flex;
  }
  &__header-line {
    margin-top: 0 !important;
  }
  &__audience-container {
    padding: 10px 0 !important;
  }
  &__radio-audience-container {
    display: flex;
    padding: 10px 30px;
  }
  &__audience-summary-header {
    color: grey;
  }
  &__btn-container {
    display: flex;
    padding-top: 40px;
  }
}
