.experiment-overrides {
  &__controls-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__auto-suggest {
    flex: 1;
    margin-right: 3rem;
    max-width: 20rem !important;
  }
  &__variant-select {
    width: 100%;
    margin-bottom: 0 !important;
  }
  &__group-values-container {
    position: relative;
    display: flex;
    &-expanded {
      flex-direction: column;
      width: 100%;
    }
  }
  &__group-no-overrides-container {
    display: flex;
    flex-direction: column;
  }
  &__group-no-overrides {
    color: #63738a;
    font-size: 0.8rem;
    font-style: italic;
  }
  &__group-collapsed-names {
    max-width: 20rem;
    white-space: nowrap;
  }
  &__group-expanded-values {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__group-expanded-name {
    font-weight: bold;
  }
  &__group-view-more {
    margin-left: 1rem !important;
    &-expanded {
      align-self: flex-end;
      margin: 0 1rem 0 0 !important;
      margin-left: unset;
    }
    &:focus,
    &:active {
      outline: none !important;
      &::before {
        border-color: transparent !important;
      }
    }
  }
  &__delete-button {
    margin-left: 1rem;
  }
  &__delete-override-btn {
    .kite-btn__content {
      color: grey;
    }
    .kite-btn-link {
      pointer-events: none;
    }
  }
  &__footer-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  &__footer-left,
  &__footer-right {
    display: flex;
  }
  * .rkp-table__headings-item.rkp-table__no-click.Type {
    position: relative;
    right: 100px;
  }
  * .rkp-table__cell {
    padding-top: 0.52rem;
    &.Variant {
      padding-top: 0;
    }
    &.Type {
      text-transform: capitalize;
      position: relative;
      right: 100px;
      width: 200px;
    }
  }
}
