.learn-article-tip {
  margin-left: 692px;
  &__link {
    padding-left: 20px;
  }
}

.audience-card {
  margin-bottom: 30px;
  &__body {
    display: flex;
  }
  &__name-description {
    width: 50%;
  }
  &__currently-personalizing {
    margin-right: auto;
  }
  &__currently-personalizing-title {
    font-weight: bold;
  }
  &__summary-footer {
    border-top: 1px solid #d8dde6;
  }
  &__summary-title {
    display: inline-flex;
    color: grey;
    margin-right: 10px;
    margin-top: 10px;
  }
}

.save-edit-buttons {
  display: inline-flex;
  padding-top: 20px;
  &__save {
    margin-right: 30px;
  }
}
