.metric-def-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1.5rem 1.5rem;
  border-radius: 0.25rem;
  background: #fff;

  &__title-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    button {
      margin-left: auto;
    }
  }
  //kite-btn__content kite-btn-standalone-link kite-btn-link
  &__fake-left {
    margin-right: auto;
    display: hidden;
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
  }

  &__main {
    display: flex;
  }

  .copy-button {
    display: flex;
    &__button {
      height: 2rem !important;
      margin-bottom: 0.5rem;
      font-size: 0.75rem !important;
      position: relative;
      left: 110px;
    }
    &__copy-icon {
      width: 2rem !important;
      height: 2rem !important;
    }
    &__success-icon {
      margin: 0 !important;
      position: relative;
      right: 40px;
      transition: transform 0.5s ease-in-out;
      > svg {
        transition: fill 0.2s ease-in-out 0.2s;
      }
      &-hidden {
        transform: rotate(-135deg);
      }
      &-with-copy-icon {
        margin-top: 0;
      }
    }
  }

  &__rows {
    display: flex;
    flex-direction: column;
    flex: 1;

    :last-child {
      flex-direction: column;

      :last-child {
        padding-left: 10px;
        padding-top: 3px;
        background: none;
        color: unset;
        font-weight: normal;
      }
    }
  }

  &__row {
    display: flex;
    font-size: 16px;

    :first-child {
      font-weight: bold;
      margin-right: 5px;
    }
  }

  &__calculation {
    overflow: scroll;
  }
}
