@import '../../../../styles/main';

.evaluation-form {
  border: 1px solid $geyser;
  &__subcategory-title {
    font-weight: 400;
    margin: 1.5rem 0;
  }
  &__expansion-panel {
    padding: 25px 45px;
    border-bottom: 1px solid $geyser;
  }
  &__subcategory-items {
    padding: 10px;
  }
  &__sub-category-header {
    display: flex;
    width: 1100px;
    justify-content: space-between;
  }
  &__input-container {
    padding: 10px 0 10px 0;
    display: flex;
  }
  &__radio-btns {
    padding-right: 10px;
  }
  &__sub-cat-power_and_sampling {
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
    &:first-child {
      min-width: 45%;
    }
  }
  &__experiment-information-objectives,
  &__experiment-information-success_metrics {
    display: flex;
    justify-content: space-between;
    margin-right: 3.5rem;
    max-height: 300px;
    margin-bottom: 20px;
  }
  &__experiment-information-power_and_sampling {
    display: flex;
    margin-right: 3.5rem;
    max-height: 300px;
    margin-bottom: 20px;
  }
  &__targetSampleSize-experiment-info {
    padding-right: 19rem;
    width: 35rem;
  }
  &__experiment-information-setup {
    margin-bottom: 20px;
    margin-right: 3rem;
  }
  &__summary-experiment-info,
  &__metrics-experiment-info {
    width: 50%;
    padding-right: 15px;
    overflow-wrap: break-word;
  }
  &__hypothesis-experiment-info {
    width: 50%;
    padding-left: 15px;
    overflow-wrap: break-word;
  }
  &__mde-experiment-info {
    padding-top: 20px;
    width: 18rem;
  }
  &__targetSampleSize-experiment-info,
  &__multipleVariantsReason-experiment-info,
  &__simultaneouslyRunningExperiment-experiment-info {
    padding-top: 20px;
  }
  &__environmentSamplings-experiment-info,
  &__experiment-information-rollout {
    max-height: 100rem;
    padding-bottom: 20px;
    width: 700px;
    padding-bottom: 10px;
    height: 100% !important;
  }
  &__durationNumDays-experiment-info {
    float: left;
  }
  &__metricCounts-experiment-info {
    position: absolute;
    top: 110px;
  }
  &__finalOutcome-experiment-info {
    overflow-wrap: break-word;
    overflow-x: hidden;
    overflow-y: visible;
    padding-left: 10rem;
    padding-bottom: 30px;
  }
  &__evaluation-item {
    padding-bottom: 10px;
    overflow-wrap: break-word;
  }
  &__display-item {
    font-weight: 500;
  }
  &__evaluation-item-commentary {
    padding-left: 40px;
  }
  .kite-radio__button {
    margin-bottom: 0.5rem !important;
  }
  .expansion-header {
    box-shadow: none !important;
    border-bottom: 1px solid $geyser;
  }
}
