@import '../../styles/main';

.radio-search-container {
  &__label {
    font-weight: 300;
  }
  &__radio-container {
    margin-bottom: 5px;
    padding-left: 15px;
    .kite-form-group {
      margin-bottom: 0px !important;
    }
  }
  &__radio-search {
    border: 1px solid $geyser;
    overflow: scroll;
    box-shadow: 0.5px 0.5px 2px 2px lighten($geyser, 10);
  }
  &__radio-search-error {
    box-shadow: 0.5px 0.5px 2px 2px $warning;
  }
  .rkp-filter-search {
    margin-bottom: 0 !important;
  }
  &__radio-btns {
    padding: 10px;
    .kite-radio__button {
      margin-bottom: 5px !important;
    }
  }
  &__no-data-message {
    font-weight: 300;
    color: $geyser;
    text-align: center;
    margin: 10px 0;
  }
  &__radio-audience-container {
    padding: 20px;
  }
  &__error-message {
    color: $warning;
  }
}
