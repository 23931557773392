.bread-crumb__container{
  display: flex;
  flex-wrap: wrap;
  .bread-crumb__item{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0 0.1875rem 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .bread-crumb__label{
    text-decoration: none;
    font-size: 1rem;
    color: #63738a;
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bread-crumb__label:hover{
    color: #0073d1;
    cursor: pointer;
  }
  .bread-crumb__label.no_click:hover{
    cursor: default;
    color: #63738a;
  }
  .bread-crumb__icon{
    font-size: 1rem;
    color: #63738a;
    margin-left: 0.75rem;
  }
}
