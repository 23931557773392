.request-form-container{
    p[class*="error"]{
        color:red
    }
    #description{
        height: 150px;
    }
    #steps-to-reproduce{
        height: 150px;
    }
    #feature-description{
        height: 150px;
    }
    #other-details{
        height: 150px;
    }
}

.request-submission-error{
    margin-top: 10px;
    color: red;
}
