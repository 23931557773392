@import '~@kite/react-kite/dist/react-kite.css';
@import '~@kite/react-kite-plus/dist/react-kite-plus.css';
@import '~@kite/react-kite/dist/kite-icons.min.css';

html,
body,
#root {
  height: 100%;
}
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: Rutledge, sans-serif;
  > * {
    box-sizing: border-box;
  }
}

.app {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    margin-bottom: 2rem;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.7);
  }
  &__loader-overlay {
    position: absolute;
    top: -1px;
    right: -1px;
    left: -1px;
    bottom: -1px;
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 5;
  }
  &__page-level-message {
    max-width: unset !important;
    margin-bottom: 1rem;
    background-color: #fff !important;
    > .kite-alert__inner {
      max-width: unset !important;
    }
  }
  &__browser-check-modal {
    width: 550px;
  }
  &__browser-check-btn {
    width: 200px;
  }
}

// KITE OVERRIDES
.kite-form-control-feedback[role='alert'],
.rkp-textarea__error-text,
.rkp-multi-search__error-message {
  font-size: 0.8rem;
  color: #d6302b;
}
.kite-alert__inner {
  max-width: unset !important;
}

.decommissionDiv {
  margin-bottom: 1rem;
}
.routes {
  max-width: 75rem;

  &__fill {
    max-width: none;
    height: 100%;
  }

  &__error {
    visibility: visible;
    width: 30rem;
    margin: 0 auto;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    &-header {
      background-color: #64748a;
      padding: 1rem 1rem;
      flex: 1;
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      color: #fff !important;
    }
    &-message {
      text-align: center;
      margin: 1.5rem;
    }
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.5s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.exp-link {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;

  div {
    min-width: 0;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .kite-icon {
    margin-left: 7px;
  }
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
