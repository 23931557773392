.page-links {
  display: flex;
  align-items: center;
}

.page-link-item {
  width: 28px;
  height: 28px;
  margin-left: 5px;
}

.page-link {
  color: #0073d1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link-selected {
  background: #0073d1;
  color: white;
  border-radius: 14px;
}
