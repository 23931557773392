.or-group-editor {
  display: grid;
  grid-template-columns: 476px 1fr;
  grid-template-rows:
    40px minmax(min-content, max-content) minmax(min-content, max-content)
    80px;
  grid-gap: 24px;
  color: #001019;

  &__details {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:
      minmax(min-content, max-content) minmax(min-content, max-content)
      minmax(min-content, max-content) minmax(min-content, max-content);
    padding: 26px 48px !important;

    &-applied {
      display: flex;
      flex-direction: column;
      max-height: 120px;
      overflow: scroll;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
  }

  &__contents {
    grid-column: 1/3;
  }

  &__delete {
    margin-top: 35px;
    justify-self: flex-end;
    .kite-btn-outline-primary {
      border-color: #d6312b;
      color: #d6312b;
    }

    .kite-btn-outline-primary:hover,
    .kite-btn-outline-primary:focus {
      background-color: #d6312b;
    }

    width: 210px !important;
  }

  &__exps {
    max-height: 120px;
    div:first-child {
      margin-bottom: 7px;
    }
  }

  &__exp-link {
    overflow: hidden;
    display: inline-block;
    width: 150px;
    text-overflow: ellipsis;
  }

  &__list {
    padding: 0px !important;
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(36px);
    color: #000d1e;
    max-height: 500px;
    overflow: scroll;

    &-empty {
      grid-column: 1/5;
      height: 100px;
      display: grid;
      align-items: center;
      padding-left: 20px;
      font-style: italic;
    }

    &-row {
      display: grid;
      grid-template-columns: minmax(0, 0.8fr) 0.5fr 1.2fr 0.2fr;
      border-bottom: solid 1px #d8dde6;
    }

    &-header {
      font-weight: 500;
      grid-column: 1/5;
      height: 44px;
      padding: 5px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #979797;
    }

    &-header-row {
      color: #63738a !important;
      .kite-icon {
        margin-right: 10px !important;
      }
    }

    &-col {
      padding: 5px;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-col-name {
      padding-left: 20px;
      font-weight: 500;
    }

    &-col-delete {
      justify-self: flex-end;
      display: flex;
      cursor: pointer;
      padding-right: 30px;
      align-items: center;
    }
  }

  &__add {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px minmax(min-content, max-content) minmax(
        min-content,
        max-content
      );

    &-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-bulk {
      color: #0062b2;
      height: 95px;
      border-bottom: solid 1px #979797;
      margin-bottom: 35px;
      > div {
        margin-top: 10px;
        display: flex;
        align-items: center;

        > a {
          margin-left: 5px;
        }

        input {
          flex: 1;
        }
      }
    }

    &-upload {
      margin-left: 30px;
    }

    &-search {
      display: flex;
      align-items: center;
    }

    &-suggest {
      flex: 1 !important;
      margin-right: 14px;
      margin-bottom: 20px !important;
      max-width: 650px !important;

      .rkp-autosuggest__suggestion {
        height: unset !important;
        padding: 3px;
      }
    }

    &-add-button {
      width: 170px;
    }
  }
}
