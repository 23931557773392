.content {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    > .experiment-variants__title {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
  &__uuid-container {
    display: flex;
    align-items: center;
  }
  &__uuid {
    margin-right: 0.5rem;
  }
  &__feature-flag {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  &__feature-flag-heading {
    font-weight: 400;
    margin-right: 1rem;
  }
  &__delete-variant {
    margin-top: 1.5rem !important;
  }
  &__add-variant {
    align-self: flex-start;
    margin: 1rem 0 3rem !important;
    font-weight: 500 !important;
    &:focus {
      text-decoration: none !important;
      &:before {
        border-color: transparent !important;
        outline: none !important;
      }
    }
    &:hover {
      text-decoration: none !important;
      // box-shadow: 0 1px 5px 0 rgba(99, 115, 138, 0.2), 0 2px 2px 0 rgba(99, 115, 138, 0.14), 0 3px 1px -2px rgba(99, 115, 138, 0.12);
    }
    > .kite-font-icon {
      height: 1.25rem !important;
      width: 1.25rem !important;
      > .si-circle-plus-f {
        height: 1.25rem !important;
        width: 1.25rem !important;
        font-size: 1.25rem !important;
      }
    }
  }
  &__error-message {
    font-size: 0.8rem;
    color: red;
  }
  .rkp-smart-exp {
    margin-bottom: 1rem;
  }
  .rkp-smart-exp__title-content {
    > span,
    p {
      font-size: 1rem;
      text-align: left;
    }
    > span {
      font-weight: 500;
      text-transform: capitalize;
    }
    > p {
      font-weight: normal;
      color: #63738a;
    }
  }
  .rkp-file-drop__dropzone {
    font-weight: normal;
  }
  &__control-content-container {
    display: flex;
  }
  &__control-content {
    margin-left: 40px;
    width: 100%;
  }
}

.personalization {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  li:not(:first-child):before {
    content: 'And ';
  }

  ul {
    list-style-type: none;
  }
}
