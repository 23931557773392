@import '../../../../styles/main';

.experiment-metrics {
  section {
    border-radius: 4px;
    border: solid 1px $geyser;
    background-color: #fff;
    margin-bottom: 16px;
    h5 {
      margin-bottom: 32px;
    }
    > div.part {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }
  }
  &__card {
    margin-bottom: 30px;
    transition: border-color 0.2s ease-in-out;
    &-error {
      border-color: $cinnabar !important;
    }
  }
  &__line {
    height: 1.5px;
    margin-bottom: 1rem;
    border: solid 1px $geyser;
  }
  &__pill-container {
    display: flex;
    flex-wrap: wrap;
  }
  &__metric {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 20px;

    .rkp-lab-toggle {
      min-width: 340px;
      p {
        text-align: left;
      }
    }
  }
  &__error-message {
    margin: 1rem 0 !important;
  }
  &__info-banner {
    background-color: white !important;
    max-width: 100% !important;
  }
}
