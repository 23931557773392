.allocation-history {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;

  &__reset {
    min-height: 4.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:first-of-type) {
      padding-top: 1rem;
      border-top: 1px solid #d8d8d8;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__reset-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #eb4b47;
    font-size: 18px;
    font-weight: 500;
    .kite-icon {
      margin-right: 10px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    &:not(:first-of-type) {
      padding-top: 1rem;
      border-top: 1px solid #d8d8d8;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__dot {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.75rem;
    border-radius: 0.25rem;
    background-color: #63738a;
  }
  &__full-name {
    width: 10.5rem;
    margin-right: 1rem;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    &:last-child {
      align-items: flex-start;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  &__progress {
    flex: 1;
    margin-right: 1rem;
  }
  &__variants {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:  17.75rem;
    margin-right: 1rem;
    margin-left: 1.25rem;
    color: #63738a;
    span {
      width: 4.875rem;
    }
  }
  &__weight-label {
    display: flex;
    justify-content: space-between;
    color: #004366;
    width: 100%;
    max-width: 320px;
  }
  &__date {
    margin-right: 0.375rem;
  }
  &__date-today {
    margin-right: 0.375rem;
    margin-left: 1.25rem;
  }
  &__time-label {
    color: #63738a;
    font-weight: 500;
  }
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__comment-container {
    padding-right: 40px;
  }
  &__comment-tooltip {
    position: absolute;
    margin-right: 20px;
    margin-top: 25px;
    background-color: white;
    border: #d8d8d8 1px solid;
    padding: 10px;
    max-height: 136px;
    max-width: 305px;
    right: 70px;
    border-radius: 4px;
    box-shadow: 0 1px 5px 0 rgba(99,115,138,.4);
    z-index: 1;
  }
}
