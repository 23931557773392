.override-management {
  &__page-controls {
    display: flex;
    justify-content: space-between;
  }
  &__experiment-button {
    margin: 1.5rem 0 !important;
  }
  &__header-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  &__create-button {
    text-decoration: none !important;
  }
  &__expansion-container {
    overflow: visible;
  }
  &__account-controls {
    display: flex;
    flex-direction: column;
  }
  &__account-input-container,
  &__group-controls {
    display: flex;
    align-items: baseline;
    > .override-management__form-input {
      flex: 1;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  &__account-device-row {
    align-items: center !important;
  }
  &__counter-input {
    > .kite-input {
      max-width: 100%;
    }
    > .rkp-counter-input__counter {
      left: unset;
      right: -2rem;
    }
  }
  &__group-values-container {
    position: relative;
    display: flex;
    &-expanded {
      flex-direction: column;
      width: 100%;
    }
  }
  &__group-collapsed-names {
    max-width: 22rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__group-expanded-values {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__group-expanded-name {
    font-weight: bold;
  }
  &__group-view-more {
    margin-left: 1rem !important;
    &-expanded {
      align-self: flex-end;
      margin: 0 1rem 0 0 !important;
      margin-left: unset;
    }
    &:focus,
    &:active {
      outline: none !important;
      &::before {
        border-color: transparent !important;
      }
    }
  }
  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  &__control-button {
    margin-right: 2rem;
    &:hover {
      color: #0073d1;
    }
    &:focus {
      outline: none;
    }
  }
  &__submit-button {
    margin-bottom: 1.5rem !important;
    max-width: 13.125rem !important;
  }
  &__table-header {
    font-size: 1.125rem;
    margin-bottom: 0;
  }
  * .rkp-table__cell {
    align-items: flex-start;
  }
  * .rkp-table__cell.Type {
    text-transform: capitalize;
  }
  * .rkp-multi-search__suggestions-container--open {
    max-height: 18.75rem !important;
  }
  &__account-name {
    font-size: 0.8rem;
    color: #63738a;
    font-style: italic;
  }

  &__plus {
    position: relative;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__success-icon {
    position: relative;
    right: 50px;
    color: #02bf20;
    transition: transform 0.8s ease-in-out !important;
    > svg {
      transition: fill 0.2s ease-in-out 0.2s;
    }
    &-hidden {
      color: transparent;
      transform: rotate(-135deg);
    }
  }
}
