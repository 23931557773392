// COLORS
// Color names from http://chir.ag/projects/name-that-color/

/// Reds
$cinnabar: #eb4b47;
$coral: #ff4d4a;
$froly: #f57878;
$mexican-red: #a42624;
$warning: #d6312b;

/// Oranges
$carrot-orange: #ee8a2a;
$muddy-waters: #b3815e;
$yellow-orange: #feb533;
$wheat: #f6d3b1;

/// Yellows
$gin-fizz: #fffae4;
$picasso: #ffec99;

/// Greens
$forest-green: #22a934;
$fun-green: #008516;
$jewel: #0d6031;
$malachite: #02bf20;
$screaming-green: #47eb7d;
$summer-green: #92c1a6;

/// Blue-Green
$trade-wind: #6abbb6;

/// Blues
$blizzard-blue: #b1e0f3;
$cerulean: #0fb0ef;
$cornflower-blue: #478eeb;
$dark-navy-blue: #001019;
$spectrum: #0073d1;

/// Purples
$daisy-bush: #4a1f9c;
$plum: #783677;
$purple-heart: #8047eb;
$thistle: #d8d0d8;

/// Earth Tones
$scorpion: #585757;

/// Grays
$alabaster: #f8f8f8;
$alto: #d8d8d8;
$dusty-gray: #979797;
$geyser: #d8dde6;
$gray-blue: #63738a;
$nepal: #96afc1;

/// Blacks
$swamp: #001019;

/// Alphas
$clear: rgba(0, 0, 0, 0);
