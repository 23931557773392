.modal-dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  padding: 1rem 1.5rem 1.5rem;
  border-radius: 0.25rem;
  background: #fff;
  text-align: center;

  .kite-icon {
    margin-left: auto;
    justify-self: flex-end;
  }

  .kite-loader {
    margin-bottom: 60px;
  }

  .message {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .sub-message {
    font-size: 16px;
    margin: 0 10px 1rem;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
  }
  &__validate-input {
    margin-left: 16%;
    margin-top: 38px;
  }
}
