@import '../../styles/main';

.experiment-details {
  display: flex;
  flex-direction: column;
  &__draft-details {
    display: flex;
    flex-direction: column;
    margin-top: 57px;
  }
  &__draft-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  &__delete-button {
    color: $froly !important;
  }
  &__environment-select {
    align-self: flex-end;
    margin-bottom: 1rem;
  }
  &__content {
    position: relative;
    display: flex;
    padding-bottom: 1.5rem;
  }
  &__uuid-expansion {
    margin-bottom: 1rem;
    * h3 {
      font-size: 1rem;
    }
  }
  &__uuid-list {
    display: flex;
    flex-flow: wrap;
    list-style-type: none;
  }
  &__uuid-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
  }
  &__uuid-label-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  &__uuid {
    font-weight: normal;
  }
  &__uuid-label {
    font-size: 0.8rem;
    color: #63738a;
    font-style: italic;
  }
  &__allocation-history,
  &__notifications {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 1.5rem;
    border: 1px solid $geyser;
    border-radius: 0.125rem;
    &:first-child {
      margin-right: 0.375rem;
    }
  }
  &__sub-title {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    > .experiment-details__view-more {
      font-weight: 500;
    }
  }
  &__view-more {
    cursor: pointer;
    font-style: normal;
    text-decoration: none;
    margin-left: 42px;
    font-weight: 500;
    color: $spectrum;
    &:focus {
      outline: none;
    }
  }
  &__notification {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1.125rem 0.375rem 1.125rem 1.5rem;
    background-color: #fff;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:not(:first-of-type) {
      border-top: 1px solid $alto;
    }
    &:hover {
      background-color: $alabaster;
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
  &__read-indicator {
    @include notificationIndicator();
    top: 1.58rem;
    left: 0.375rem;
    &-read {
      background-color: $clear;
    }
  }
  &__notification-left,
  &__notification-right {
    display: flex;
    color: #000;
    font-size: 1rem;
  }
  &__notification-right {
    align-items: flex-end;
    font-weight: normal;
  }
  &__notification-subject {
    font-weight: normal;
    &-unread {
      font-weight: bold;
    }
  }
  &__notification-date {
    margin-right: 0.375rem;
  }
  &__notification-time {
    color: $gray-blue;
    font-weight: 500;
  }
  &__empty {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;
  }
  &__environment-controls {
    display: flex;
  }
  &__environment-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    border: none;
    border-radius: 2rem;
    text-transform: capitalize;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &:hover {
      opacity: 0.85;
    }
  }
  &__environment-button-value {
    margin-right: 1.5rem;
    color: #fff;
    font-size: 0.875rem;
  }
  &__environment-button-icon {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 7px solid #fff;
  }
  &__footer-controls {
    display: flex;
  }
  &__cancel-button-disabled {
    .kite-btn__content {
      color: grey;
    }
    .kite-btn-link {
      pointer-events: none;
    }
  }
  &__clone-button {
    margin-right: 2rem !important;
  }
  &__delete-button {
    color: $froly !important;
  }
  &__delete-button-disabled {
    .kite-btn__content {
      color: grey;
    }
    .kite-btn-link {
      pointer-events: none;
    }
  }
  &__loader {
    height: 7rem;
    padding: 1.5rem;
  }
  * .allocation-history {
    height: unset;
    margin-top: 1.5rem;
    &__empty {
      margin-top: 1.5rem;
    }
  }
}
