@import '../../../../styles/main';

.general {
  height: 100%;
  border: 1px solid $geyser;
  padding: 80px;
  &__evaluators-names {
    margin-top: 5px;
  }
  &__evaluators {
    margin-bottom: 20px;
  }
  &__portfolio-category {
    margin-top: 30px;
    margin-right: 10px;
  }
  &__portfolio-category-no-suggestion {
    margin-right: 20px;
  }
  &__portfolio-category-no-suggestion-cat {
    padding-top: 5px;
  }
  &__ad-hoc-evaluators-names {
    margin-top: 30px;
  }
  &__no-evaluators {
    font-weight: 500;
  }
  &__portfolio-product-container {
    display: flex;
  }
  &__portfolio-input-conatiner {
    display: flex;
  }
  &__portfolio-hint-text {
    padding-right: 15px;
  }
  &__product-feature-container {
    display: flex;
    padding-bottom: 10px;
  }
  &__product {
    padding-right: 30px;
  }
  &__metric-names {
    padding-top: 5px;
  }
  &__secondary-metrics {
    padding-bottom: 10px;
  }
  &__evaluation-history {
    margin-top: 30px;
  }
  &__line-divider {
    margin-bottom: 30px;
  }
  &__ad-hoc-users_container {
    display: flex;
  }
  &__ad-hoc-input {
    padding-top: 4px;
    margin-right: 30px;
  }
  &__evaluator-pills {
    display: flex;
  }
  &__header-questions {
    font-weight: 500;
  }
  &__experiment-history-table {
    border: 1px solid darken($geyser, 20);
    width: 100%;
  }
  &__experiment-history-headers {
    border-bottom: 1px solid darken($geyser, 20);
    padding: 1rem 24.5rem 1rem 2.5rem;
    display: flex;
    justify-content: space-between;
    span {
      color: lighten(#000, 50);
    }
  }
  &__experiment-history-items {
    padding: 15px 30px 15px 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darken($geyser, 10);
  }
  &__history-username {
    text-align: left;
    width: 6.5rem;
  }
  &__changed-field-json {
    overflow-wrap: break-word;
    padding-bottom: 10px;
  }
  &__change-made-header {
    width: 200px;
  }
  &__changes-made {
    width: 35rem;
    overflow-wrap: break-word;
  }
  &__complete-status {
    padding-left: 40px;
  }
}
