.dialog-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  background-color: #fff;
  border-radius: 0.25rem;
}

.dialog-header__wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 3rem;
  padding: 1rem 1rem 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  &.with-header-title {
    justify-content: space-between;
    height: unset;
  }
}

.close-button {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    color: #0073d1;
  }
}

.dialog-body {
  width: 700px;
  max-height: 500px;
  padding: 1.5rem 1.5rem;
  overflow: auto;
}

.dialog-controls__container {
  display: flex;
  justify-content: flex-end;
  padding: 0.125rem;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2),
    0px -2px 5px 0px rgba(0, 0, 0, 0.14), 0px -4px 10px 0px rgba(0, 0, 0, 0.12);
    z-index: 1;
}
