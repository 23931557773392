@import '../../styles/main';

.rollout-experiment {
  position: relative;
  * .rkp-stepper__label {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  &__environment-select-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  &__environment-wrapper {
    display: flex;
    margin-bottom: 0.5rem;
  }
  &__environment-label {
    margin-right: 1.25rem;
    margin-bottom: 0;
    color: $gray-blue;
    font-size: 1rem;
    font-weight: 500;
  }
  &__environment-select {
    margin-right: 0.75rem;
  }
  &__environment-status {
    display: flex;
    margin-right: 1rem;
    font-size: 0.875rem;
    > .tag {
      color: #000;
      font-weight: bold;
    }
    > .status {
      text-transform: capitalize;
    }
    > .testing,
    .running {
      color: $forest-green;
    }
    > .draft {
      color: $gray-blue;
    }
    > .completed {
      color: $spectrum;
    }
    > .cancelled {
      color: $cinnabar;
    }
  }
  &__accent-color-container {
    position: relative;
  }
  &__accent-color {
    position: relative;
    top: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem 0.25rem 0 0;
    z-index: 1;
  }
  &__accent-color-children {
    position: relative;
    height: 100%;
    padding: 1rem;
    border: 1px solid $geyser;
    border-radius: 0.25rem;
    background-color: #fff;
  }
  &__footer {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: space-between;
    &.error {
      margin-bottom: 1rem;
    }
  }
  &__launch-button {
    margin-right: 2rem !important;
  }
  &__error-message {
    font-size: 0.8rem;
    color: red;
  }
  * .rkp-textarea__warning-text {
    color: red;
    font-weight: 400;
  }

  &__cancel-complete {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    button:first-child {
      margin-right: 20px !important;
    }
  }
}
