@import '../../../../styles/main';

.sample-launch {
  &__heading {
    margin-top: 2rem;;
    margin-bottom: 1rem;
  }
  &__description {
    margin-bottom: 3rem;
    font-size: 1rem;
  }
  &__data-container {
    position: relative;
    display: flex;
    width: 100%;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
  &__section-heading {
    margin-bottom: 2.5rem;
    margin-left: 1.5rem;
    color: $scorpion;
  }
  &__allocation-chart,
  .rkp-lac {
    margin-bottom: 2.875rem;
    &__item-indicator {
      padding-right: 10px;
    }
    &__item-label {
      padding-left: 10px;
    }
  }
  &__sampling-allocation-controls {
    display: flex;
    margin-bottom: 2.75rem;
  }
  &__sampling-container {
    max-height: 16.5rem;
    margin-right: 0.25rem;
    padding: 1.25rem;
    border: 1px solid $geyser;
    border-radius: 0.125rem;
  }
  &__allocation-controls {
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
  }
  &__variants-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
  }
  &__variant {
    display: flex;
    flex-direction: column;
    max-width: 8.25rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border: 1px solid $geyser;
    border-radius: 0.125rem;
  }
  &__variant-name {
    width: 6.5rem;
    margin-bottom: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__variant-wrapper {
    display: flex;
    align-items: flex-end;
  }
  &__variant-input {
    width: unset !important;
    margin-bottom: 0 !important;
    margin-right: 0rem;
    > input,
    input:focus {
      width: 3.5rem;
      max-width: 4rem;
      padding: 0.25rem 0.25rem 0.25rem 1.25rem;
      border-width: 2px !important;
    }
    &.control > input {
      border-color: $cornflower-blue;
    }
    &.variant-1 > input {
      border-color: $plum;
    }
    &.variant-2 > input {
      border-color: $carrot-orange;
    }
    &.variant-3 > input {
      border-color: $jewel;
    }
    &.variant-4 > input {
      border-color: $picasso;
    }
    &.variant-5 > input {
      border-color: $blizzard-blue;
    }
    &.variant-6 > input {
      border-color: $thistle;
    }
    &.variant-7 > input {
      border-color: $wheat;
    }
    &.variant-8 > input {
      border-color: $summer-green;
    }
    &.variant-9 > input {
      border-color: $swamp;
    }
  }
  &__variant-percent {
    margin-bottom: 0.75rem;
    margin-left: 0.5rem;
    color: $gray-blue;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &__distribute-button {
    align-self: center;
    border-radius: 1.25rem !important;
  }
  &__history {
    height: 100%;
  }
  &__allocation-wrapper {
    position: relative;
    min-height: 30rem;
    padding-right: 2.5rem;
    padding-left: 1rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0.875rem;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.125rem solid #ffffff;
      border-radius: 1rem;
      background-color: $gray-blue;
    }
    > .allocation-history {
      margin-bottom: 1.5rem;
    }
  }
  &__history-max-height-below5 {
    max-height: 30rem !important;
  }
  &__history-max-height-5-6 {
    max-height: 38rem !important;
  }
  &__history-max-height-6-7 {
    max-height: 48rem !important;
  }
  &__history-max-height-above8 {
    max-height: 58rem !important;
  }
  &__allocation-wrapper-fade {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: 90%;
    height: 2.5rem;
    background-image: linear-gradient(
      to top,
      #ffffff,
      rgba(255, 255, 255, 0.5)
    );
  }
  &__error-message {
    margin-top: 1rem;
  }

  &__check-modal {
    .primary,
    .primary:hover {
      background-color: #eb4b47;
    }
  }
  &__launch-modal {
    .modal__deny-button {
      color: #eb4b47;
      border-color: #eb4b47;
    }
    .modal__deny-button:hover {
      color: white;
      border-color: white;
      background-color: #eb4b47;
    }
  }
  &__comment-container {
    margin-top: 20px;
  }
  &__active-lockdown-btn {
    .kite-btn__content {
      color: grey;
    }
    .kite-btn-link {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 1509px) {
  .sample-launch {
    &__sampling-allocation-controls {
      flex-direction: column;
      align-items: center;
    }
    &__sampling-container {
      display: flex;
      justify-content: center;
      max-width: 16.5rem;
      margin-bottom: 2rem;
    }
    &__allocation-controls {
      align-items: center;
      margin-left: 0;
    }
    &__variants-container {
      justify-content: space-between;
      width: 17rem;
    }
    &__variant {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 1350px) {
  .sample-launch {
    &__data-container {
      flex-direction: column;
      > * {
        &:first-child {
          margin-bottom: 2rem;
        }
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
    &__sampling-allocation-controls {
      flex-direction: row;
      justify-content: center;
    }
  }
}
#root > div > div.navigation-content__wrapper > div.navigation-content__children-wrapper > div.routes > div > div.sample-launch > div:nth-child(6) > div > div.rollout-experiment__accent-color-children > div{
  display: flex;
  margin-right: 200px;;
  margin-left: 200px;;
  justify-content: space-between;
  input{
    margin-right: 10px;
  } 
}

