@import '../../styles/main';

.experiment-list {
  margin: 0 auto;
  &__controls {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.5rem;
    > .perfect-button,
    * .perfect-button {
      margin: 0;
    }
  }

  &__count {
    display: flex;
    justify-content: flex-end;
    background: #f8f8f8;
    padding-bottom: 10px;
    font-style: italic;
    font-size: 16px;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 38.125rem;
    margin-right: 2.5rem;
    .rkp-filter-search {
      flex: 1;
      margin-right: 1rem;
    }
  }
  &__table {
    background-color: #fff;
    * .rkp-stepper {
      margin-bottom: 0;
      padding: 0 1rem;
      &__label {
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }
    * .rkp-table__cell.Status {
      text-transform: capitalize;
    }
    * .rkp-table__row-exp-content--open {
      max-height: 1500px;
    }
  }
  &__read-indicator {
    @include notificationIndicator();
    position: initial;
    &-read {
      background-color: $clear;
    }
  }
  &__item-status {
    &-completed {
      color: $fun-green;
    }
    &-cancelled {
      color: $froly;
    }
  }
  &__overtime {
    color: $cinnabar;
  }
  &__delete-experiment-modal {
    width: 450px;
  }
  &__delete-experiment-btn {
    width: 200px;
  }
}
