.header-menu {
  display: flex;
  justify-content: space-between;
  height: 5.625rem;
  padding: 0 3rem;
  border: 1px solid #d8dde6;
  border-left: 0;
  background-color: #fff;
  &__left {
    display: flex;
  }
  &__right {
    display: flex;
  }
  &__location-container {
    display: flex;
    flex-direction: column;
    margin-right: 5rem;
  }
  &__logo-container {
    padding-top: 20px;
  }
  &__location-title {
    padding: 2rem 0;
    font-size: 1.5rem !important;
    font-weight: bold;
    text-transform: capitalize;
    &-with-subtitle {
      margin: 0 !important;
      padding-top: 0.875rem;
      padding-bottom: 0.375rem;
    }
  }
  &__location-sub-title {
    padding: 0 !important;
    padding-bottom: 0.75rem !important;
    color: #63738a !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    text-transform: capitalize;
  }
  &__menu-icon-container {
    align-self: center;
    display: flex;
  }
  &__icon-container {
    position: relative;
    margin-left: 2.375rem;
  }
  &__menu-icon {
    position: relative;
    margin-left: 2.375rem;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:focus {
      outline: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover,
    &-selected {
      color: #0073d1;
      > svg {
        fill: #0073d1;
      }
    }
    &-settings:hover {
      animation: spin 2.5s infinite linear;
    }
    &-notifications {
      transform-origin: top;
      &:hover {
        animation: jingle 0.75s;
      }
    }
    &-search:hover {
      animation: zoom 1.25s;
    }
  }
  &__calculator-icon-inactive {
    height: 21px;
    cursor: pointer;
    filter: grayscale(100%);
    &:hover {
      filter: none;
    }
  }
  &__calculator-icon-active {
    height: 21px;
    cursor: pointer;
  }
  &__icon-indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.3rem;
    right: 0.05rem;
    width: 0.5625rem;
    height: 0.5625rem;
    border-radius: 100%;
    color: white;
    background: #ff4d4a;
    border: 1px solid #fff;
    cursor: pointer;
    &-value {
      top: -0.25rem;
      right: -0.45rem;
      width: 1.125rem;
      height: 1.125rem;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }
  &__sub-options {
    position: absolute;
    top: 4.25rem;
    right: 16.75rem;
    display: flex;
    flex-direction: column;
    min-width: 16.5rem;
    border: 1px solid #b7bcc4;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 3px 14px 2px rgba(99, 115, 138, 0.12),
      0 8px 10px 1px rgba(99, 115, 138, 0.14),
      0 5px 5px 0 rgba(99, 115, 138, 0.2);
    overflow: hidden;
    z-index: 100;
  }
  &__sub-options-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid rgb(216, 221, 230);
  }
  &__sub-options-title {
    font-size: 1.125rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  &__sub-options-sub-title {
    color: #4a4a4a;
    font-size: 1rem;
  }
  &__sub-option-icon {
    margin-right: 2.625rem !important;
    color: #7e888b;
    > svg {
      fill: #7e888b;
    }
  }
  &__sub-option-link {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.25rem 2.25rem;
    border-radius: 0.25rem;
    color: #000;
    font-weight: normal !important;
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;
    &:hover,
    &-selected {
      color: #0073d1;
      background-color: #ebecee;
      text-decoration: none;
      > .header-menu__sub-option-icon {
        color: #0073d1;
        > svg {
          fill: #0073d1;
        }
      }
    }
    &:focus {
      outline: none;
    }
    &:last-child {
      padding-bottom: 1.5rem;
    }
    &-selected {
      cursor: default;
      pointer-events: none;
    }
  }
  button[title='id'] {
    text-transform: uppercase;
  }

  @media screen and (max-width: 1535px) {
    .header-menu {
      &__right {
        flex-direction: column-reverse;
      }
      &__menu-icon-container {
        margin-bottom: 1.75rem;
      }
    }
  }

  @media screen and (max-width: 1595px) {
    .header-menu {
      &__location-title {
        max-width: 450px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes jingle {
    0% {
      transform: rotate(0deg);
    }
    15% {
      transform: rotate(-15deg);
    }
    30% {
      transform: rotate(15deg);
    }
    45% {
      transform: rotate(-15deg);
    }
    60% {
      transform: rotate(15deg);
    }
    90% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes zoom {
    0% {
      transform: rotate(0deg) scale(1);
    }
    25% {
      transform: rotate(-45deg);
    }
    50% {
      transform: rotate(-45deg) scale(1.25);
    }
    75% {
      transform: rotate(-45deg) scale(0.85);
    }
    95% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
}
