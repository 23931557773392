@import '../../../../../styles/main';

.evaluation-experiment-summary {
  padding: 10px;
  border: 1px solid darken($geyser, 15);
  height: 100%;
}

.evaluation-experiment-reasoning {
  padding: 10px;
  border: 1px solid darken($geyser, 15);
  height: 130px;
}

.metric-list {
  padding-left: 10px;
  font-weight: 500;
}

.sample-size {
  display: flex;
  width: 20rem;
  &__number {
    border: 1px solid darken($geyser, 15);
    padding: 10px;
  }
  &__planned-length {
    padding-right: 30px;
  }
}

.variant-container {
  padding-top: 10px;
  .kite-card,
  .expansion-container {
    box-shadow: none !important;
  }
  &__expansion-panel {
    margin-bottom: 15px !important;
  }
  &__header-info {
    padding-right: 15px;
    padding-bottom: 15px;
  }
  &__json {
    margin-top: 10px !important;
  }
}

.experiment-allocation-history {
  border: 1px solid darken($geyser, 15);
  padding: 15px;
}

.eval__allocation-history {
  overflow: scroll;
}
