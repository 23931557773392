.copy-button {
  display: flex;
  &__button {
    height: 1.5rem !important;
    margin-bottom: 0.5rem;
    font-size: 0.75rem !important;
  }
  &__copy-icon {
    width: 1rem;
  }
  &__success-icon {
    margin: 0.5rem 1rem;
    transition: transform 0.5s ease-in-out;
    > svg {
      transition: fill 0.2s ease-in-out 0.2s !important;
    }
    &-hidden {
      transform: rotate(-135deg);
    }
    &-with-copy-icon {
      margin-top: 0;
    }
  }
}
