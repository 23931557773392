@import '../../../../styles/main';

.tdcs-status {
  &__heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__heading-status {
    padding: 10px;
  }
  &__publish-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
    margin-bottom: 50px;
  }
  &__accent-color-children {
    padding: 1.25rem 0 !important;
  }
  &__publish-history {
    display: flex;
    flex-direction: column;
    max-height: 16.5rem;
    overflow-y: scroll;
    padding: 1.25rem 0;
    max-height: 500px;
  }
  &__publish-history-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 0px 30px;
  }
  &__publish-history-item {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 20px 35px;
    border-top: 1px solid $geyser;
    :nth-child(2) {
      margin-left: 15px;
    }
  }
  &__publish-header {
    display: flex;
  }
  &__history-display-name {
    display: flex;
    width: 200px;
  }
  &__no-data {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 100px;
    font-size: 20px;
  }

  pre {
    white-space: pre-wrap;
  }

  &__config-container {
    position: relative;
    display: flex;
    width: 100%;
    > * {
      flex: 1;
      &:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
  &__display-name {
    color: black !important;
    font-size: 16px !important;
    font-weight: normal !important;
    width: 190px;
  }
  &__experiment-status {
    width: 245px;
    display: flex;
    align-items: center;
  }
  &__experiment-reset {
    word-wrap: break-word;
  }
  &__date-display {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  &__published-by {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__is-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:first-of-type) {
      padding-top: 1rem;
      border-top: 1px solid #d8d8d8;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    color: black;
    .kite-icon {
      margin-right: 10px;
      min-height: 45px;
      min-width: 17px;
    }
  }
  &__config-display {
    overflow-y: scroll;
    max-height: 500px;
  }
  &__config {
    font-family: Rutledge !important;
  }
  &__config-btn {
    max-width: 210px;
    text-align: center;
  }
}
