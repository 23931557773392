.audience-management {
  .rkp-table__cell:last-child {
    color: #0073d1;
  }
  .rkp-table__headings {
    grid-template-columns: minmax(0px, 1.2fr) minmax(0px, 1.9fr) minmax(
        0px,
        1fr
      ) !important;
    grid-gap: 0 !important;
  }
  .rkp-table__headings-wrapper {
    border-bottom: 1px solid grey;
  }

  &__quick-view {
    min-height: 150px;
    padding: 10px;
    display: grid;
    grid-template-columns: 0.5fr 1px 1fr;
    grid-template-rows: 1fr;
  }

  &__quick-view-divider {
    border: solid 0.5px #ededed;
    align-self: center;
    height: 100%;
  }

  .rkp-table__row-title {
    border-bottom: 0.5px solid #d8dde6;
  }

  &__quick-view-titles {
    color: grey;
    padding-bottom: 2px;
  }

  &__quick-view-details {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    overflow: scroll;
  }

  &__quick-view-values {
    padding-bottom: 20px;
  }

  &__content-info {
    color: black;
    padding-left: 10px;
  }

  .kite-pagination {
    width: 100%;
    text-align: left !important;
    padding-top: 15px;
    .page-actions {
      float: right;
    }
  }
}
