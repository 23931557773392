.create {
  &__info-main {
    width: 100%;
    display: flex;
  }
  &__left-data {
    width: 480px;
  }
  &__right-data {
    width: 50%;
    padding-left: 55px;
  }
  &__business-use-case-label {
    padding-bottom: 6px;
  }
  &__map-audiences-content {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  &__personalized-content-btn-container {
    display: flex;
    justify-content: space-between;
  }
  &__audience-management-link-container {
    display: inline-grid;
  }
  &__audience-question {
    margin-bottom: 19px;
    font-weight: bold;
  }
  &__audience-management-link {
    margin-left: 15%;
  }
  &__personalized-content-container,
  &__default-container {
    display: flex;
    margin: 28px 0.2px 15px 0;
    border-top: dashed 1px #979797bb;
    border-bottom: none;
    border-width: 2px;
    padding-top: 20px;
    justify-content: space-between;
  }
  &__default-container {
    border-top: solid 3px #d8d8d8;
    padding-right: 65px;
  }
  &__up-btn-container {
    margin-top: 10px;
  }
  &__down-btn-container {
    margin-top: 10px;
  }
  &__delete-btn {
    margin-top: 30px;
    .kite-btn__content {
      max-width: 30px;
    }
  }
  &__experiment-link-audience-container {
    width: 400px;
  }
  &__default-label-container {
    font-weight: bold;
    margin: 20px 33px 0;
    width: 150px;
  }
  &__audience-priority-content-container {
    margin-bottom: 40px;
  }
  &__btn-container,
  &__btn-save-cancel {
    display: flex;
    justify-content: space-between;
  }
  &__exit-btn {
    margin-left: 64px;
  }
  &__btn-disabled {
    .kite-btn__content {
      color: #d8dde6;
      &:hover,
      &:focus {
        color: #d8dde6;
        text-decoration: none;
      }
      cursor: auto;
    }
  }
  &__date-time-container {
    max-width: 505px;
  }
  &__date-time {
    display: flex;
    justify-content: space-between;
  }
  &__delete-btn-disabled {
    margin-top: 30px;

    .kite-btn__content {
      color: #d8dde6;
      &:hover,
      &:focus {
        color: #d8dde6;
        text-decoration: none;
      }
      cursor: auto;
      max-width: 30px;
    }
  }
  &__comment {
    position: absolute;
    bottom: 0;
    padding-bottom: 25px;
    width: 85%;
  }
  &__business-case-error {
    color: #d6312b;
    margin-bottom: 1rem;
  }
  &__check-52 {
    margin-left: 30px;
  }
  &__refresh-btn {
    padding-left: 40px !important;
  }
  &__cancel-content-btn {
    .kite-btn__content {
      background-color: #ffff;
      color: #dd1c1a;
      border-color: #dd1c1a;
      &:focus,
      &:hover {
        background-color: #dd1c1a;
      }
    }
  }
}
