@import '../../styles/main';

.set-up-experiment {
  * .rkp-stepper__label {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  &__footer {
    display: flex;
    &.error {
      margin-bottom: 1rem;
    }
  }
  &__continue-button {
    margin-right: 2rem !important;
  }
  &__error-message {
    font-size: 0.8rem;
    color: red;
  }
  * .rkp-textarea__warning-text {
    color: red;
    font-weight: 400;
  }
  .app__page-level-message {
    * .kite-alert__link-text {
      color: $spectrum;
    }
  }
}
