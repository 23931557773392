@import '../../styles/main';

.experiment-results {
  &__no-sig-banner {
    margin-top: 10px;
  }

  &__dim-header {
    color: #63738a;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
  }
  &__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .kite-input__label,
    .kite-select__label {
      font-weight: bold;
    }
  }

  &__summary {
    display: flex;
    flex-direction: column;
  }

  &__summary-block {
    display: flex;
    line-height: 24px;
    margin-bottom: 6px;

    div:first-child {
      font-weight: bold;
      margin-right: 8px;
    }
  }

  &__summary-dates {
    display: flex;
    justify-content: space-between;
  }

  &__summary-vert-block {
    display: flex;
    flex-direction: column;

    div:first-child {
      font-weight: bold;
      margin-right: 8px;
    }
  }

  &__dates {
    color: $dusty-gray;
    font-size: 1rem;
    font-style: italic;
  }
  &__select-sub-title {
    font-size: 1rem;
    color: $gray-blue;
    font-style: italic;
  }
  &__variants {
    display: flex;
    align-items: center;
    .kite-form-group {
      margin-bottom: 0.25rem;
    }
  }
  &__select-container {
    display: flex;
    flex-direction: column;
    width: 250px;
  }
  &__variant-vs {
    margin: 0 0.625rem;
  }
  &__counts {
    display: flex;
    div {
      margin-right: 57px;
    }
  }
  &__final-outcome {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    .kite-font-icon {
      margin-right: 5px;
    }
  }
  &__report-link {
    text-decoration: none;
  }
  &__metric-type-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    padding: 2rem 0;
    border: 1px solid $geyser;
    border-radius: 0.25rem;
    background: #fff;
  }
  &__tab-container {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid $dusty-gray;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  &__table-container {
    width: 100%;
    padding: 3rem;
    padding-bottom: 0rem;
  }
  &__mean-effect-container {
    display: flex;
    align-items: center;
    > .kite-icon {
      margin-right: 0.5rem;
    }
  }
  &__mean-effect {
    color: $gray-blue;
    &-pos-impact {
      color: $malachite;
      font-weight: 500;
    }
    &-neg-impact {
      color: $coral;
      font-weight: 500;
    }
  }
  &__p-value {
    font-weight: bold;
    &-significant {
      color: $dark-navy-blue;
    }
    &-not-significant {
      color: $dusty-gray;
    }
    &-not-display {
      color: #63738a;
    }
  }
  &__not-running {
    margin-top: 50px;
  }
  &__error {
    margin-top: 10px;
    color: red;
  }
  &__not-canary-results {
    grid-template-columns:
      minmax(0px, 1.75fr) minmax(0px, 1fr) minmax(0px, 0.7fr)
      minmax(0px, 1fr) minmax(0px, 0.85fr) minmax(0px, 0.5fr) minmax(0px, 2fr)
      minmax(0px, 0.2fr) !important;
  }
  &__canary-results {
    grid-template-columns: minmax(0px, 1.2fr) minmax(0px, 1fr) minmax(
        0px,
        1.2fr
      );
  }
  .rkp-datepicker {
    margin-bottom: 0.25rem;
  }
  .rkp-calendar__date--today {
    background-color: rgb(248, 248, 248) !important;
  }
  .rkp-table {
    &__headings-wrapper {
      position: relative;
      border: none;
      border-bottom: 1px solid $dusty-gray;
    }

    &__headings-item {
      font-weight: normal;
      white-space: unset;
      align-items: flex-end;
      &:focus {
        outline: 0;
      }
    }

    &__row {
      border-right: none;
      border-left: none;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__cell {
      color: $gray-blue;
      &.Metric {
        color: $dark-navy-blue;
        font-weight: bold;
      }
    }
    &__row-exp-content--open {
      max-height: 1500px;
    }
  }
  &__not-running {
    margin-top: 50px;
  }
  &__error {
    margin-top: 10px;
    color: red;
  }
  .rkp-datepicker {
    margin-bottom: 0.25rem;
    &__calendar-container {
      top: 20px !important;
      left: 18.25rem !important;
    }
    &__date--today {
      background-color: rgb(248, 248, 248) !important;
    }
  }
  .submetric-container {
    width: 100%;
    margin-bottom: 15px;
    &__borderTop {
      border-top: 1.5px solid #f8f8f8;
    }
    &__subdimension-name {
      padding-top: 5px;
      margin-left: 20px;
    }
    &__submetric-standard-layout {
      display: grid;
      grid-template-columns: minmax(0px, 0.6fr) minmax(0px, 0.45fr) minmax(
          0px,
          0.4fr
        ) minmax(0px, 0.8fr) minmax(0px, 0.9fr) minmax(0px, 0.35fr);
      grid-gap: 16px;
      height: 27px;
      margin: 10px 260px 10px 30px;
    }
    &__submetric-canary-layout {
      display: grid;
      grid-template-columns: minmax(0px, 1.2fr) minmax(0px, 1fr) minmax(
          0px,
          1.2fr
        );
      grid-gap: 10px;
      height: 27px;
      margin: 10px 10px 10px 145px;
    }
    &__submetric-name {
      display: flex;
      padding-right: 10px;
      justify-content: flex-end;
    }
    &__submetric-significant-red {
      background-color: rgba(214, 49, 43, 0.1);
    }
    &__submetric-significant-green {
      background-color: rgba(34, 169, 52, 0.1);
    }
  }
  .Confidence {
    display: flex;
    justify-content: center;
  }
  &__not-significant {
    padding-left: 1.5rem;
  }

  .no-focus:focus {
    outline: 0;
  }
}
