.experiment-variants {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    > .experiment-variants__title {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }
  }
  &__uuid-container {
    display: flex;
    align-items: center;
  }
  &__uuid {
    margin-right: 0.5rem;
  }
  &__feature-flag {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  &__feature-flag-heading {
    font-weight: 400;
    margin-right: 1rem;
  }
  &__json-container {
    margin: 1rem 0;
  }
  &__json-builder {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 0.2fr;
    grid-column-gap: 0.5rem;
    > .kite-btn {
      justify-content: center;
      align-items: center;
      height: 2.75rem;
      margin-right: 10px;
      margin-top: 1.2rem !important;
    }
  }
  &__json-builder-values {
    display: flex;
    flex-direction: column;
    max-width: 55rem;
    padding: 0.75rem 2rem 0.75rem 0.625rem;
    color: #2f313a;
    background-color: #f8f8f8;
    font-weight: normal;
  }
  &__json-line {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0.5rem;
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    &:hover * .kite-icon {
      opacity: 100;
    }
  }
  &__json-line-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > .kite-icon {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    > .kite-icon:hover {
      fill: #0073d1;
    }
  }
  &__custom-json {
    position: relative;
  }
  &__custom-json-controls {
    position: absolute !important;
    right: 0;
    top: -2rem;
  }
  &__custom-json-presentation {
    padding: 0.625rem;
  }
  &__file-drop-container {
    position: relative;
  }
  &__file-drop-loader-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
  }
  &__delete-variant {
    margin-top: 1.5rem !important;
  }
  &__add-variant {
    align-self: flex-start;
    margin: 1rem 0 3rem !important;
    font-weight: 500 !important;
    &:focus {
      text-decoration: none !important;
      &:before {
        border-color: transparent !important;
        outline: none !important;
      }
    }
    &:hover {
      text-decoration: none !important;
      // box-shadow: 0 1px 5px 0 rgba(99, 115, 138, 0.2), 0 2px 2px 0 rgba(99, 115, 138, 0.14), 0 3px 1px -2px rgba(99, 115, 138, 0.12);
    }
    > .kite-font-icon {
      height: 1.25rem !important;
      width: 1.25rem !important;
      > .si-circle-plus-f {
        height: 1.25rem !important;
        width: 1.25rem !important;
        font-size: 1.25rem !important;
      }
    }
  }
  &__custom-disabled {
    .kite-btn__content {
      color: grey;
      &:hover,
      &:focus {
        color: grey;
        text-decoration: none;
      }
    }
  }
  &__error-message {
    font-size: 0.8rem;
    color: red;
  }
  .rkp-smart-exp {
    margin-bottom: 1rem;
  }
  .rkp-smart-exp__title-content {
    > span,
    p {
      margin-bottom: 0;
      font-size: 1rem;
    }
    > span {
      font-weight: 500;
      text-transform: capitalize;
    }
    > p {
      font-weight: normal;
      color: #63738a;
    }
  }
  .rkp-file-drop__dropzone {
    font-weight: normal;
  }
}
