.lockdown-schedule-text {
  &__not-scheduled {
    margin-top: 2rem;
    color: rgba(0, 128, 0, 0.781);
  }
  &__scheduled {
    margin-top: 2rem;
    color: black;
    font-weight: bold;
  }
}

.divider {
  margin-top: 2rem;
}

.lockdown-details-header {
  margin-top: 2rem;
}

.immediate-checkbox {
  margin-top: 2rem;
  width: 100%;
}

.date-time-inputs {
  display: flex;
  height: 7rem;
  &__start {
    width: 50%;
    display: inline-flex;
    align-items: center;
  }
  &__end {
    width: 50%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__start-date {
    margin-right: 1rem;
  }
  &__end-date {
    margin-right: 1rem;
  }
  &__end-lockdown {
    width: 50%;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.save-cancel-buttons {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  padding-top: 20px;
  &__save-cancel {
    display: inline-flex;
  }
  &__cancel-changes {
    align-items: center;
  }
  &__cancel-lockdown {
    .kite-btn__content {
      background-color: #ffff;
      color: #dd1c1a;
      border-color: #dd1c1a;
      &:focus,
      &:hover {
        background-color: #dd1c1a;
      }
    }
  }
}

.cancel-modal {
  height: 12rem;
  width: 25rem;
}
