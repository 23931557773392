@import '../../../../styles/main';

.experiment-tech-settings {
  margin-bottom: 64px;
  &__title {
    font-size: 20px;
  }
  &__subtext {
    font-size: 16px;
    color: #63738a;
    font-weight: 300;
  }
  &__group-label {
    margin-top: 24px;
    font-size: 16px;
    margin-bottom: 0;
  }
  &__version-header-wrapper {
    display: flex;
    :first-child {
      padding-right: 270px;
    }
  }
  &__av-platform-versions {
    display: flex;
    padding-left: 50px;
  }
  &__version-wrapper {
    display: flex;
    margin-top: 16px;
  }
  &__filter-categories-wrapper {
    flex: 1;
    display: flex;
    margin-top: 24px;
  }
  &__filter-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__filter-category {
    &:not(:first-child) {
      margin-left: 48px;
    }
  }
  &__filter-category-title {
    margin-bottom: 16px;
  }
  &__custom-filters-wrapper {
    margin-top: 24px;
  }
  &__filter-checkbox {
    margin-bottom: 8px;
  }
  &__input-wrapper {
    position: relative;
  }
  &__include-exclude-switch {
    position: absolute !important;
    left: 288px;
    top: -0.25rem;
    height: 20px !important;
    font-size: 0.75rem;
    transform: translateX(-100%);
    &::after {
      height: 17px !important;
    }
  }
  &__help-text {
    padding-top: 0.5px;
    margin-left: auto;
  }
  &__wiki-link-out {
    padding-left: 5px;
  }
  &__network-type {
    display: flex;
    flex-direction: row;
  }
  &__regular-type {
    display: flex;
    flex-direction: column;
  }
}

.dynamic-tech-settings {
  &__filters-card {
    display: flex;
    min-height: 465px;
    margin: 20px 0 20px;
    padding: 0 !important;
  }

  &__additional-card {
    display: flex;

    .experiment-tech-settings__custom-filters-wrapper {
      display: flex;
      flex-wrap: wrap;

      .experiment-tech-settings__input-wrapper {
        width: 300px;
        margin-right: 20px;
      }
    }
  }

  &__left {
    flex: 1;
    margin-right: 64px;

    .kite-radio {
      height: 100%;
      padding: 0 !important;
      margin: 0 !important;
      &__button-container {
        height: 100%;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex: 2.7;
  }

  &__no-available {
    display: flex;
    height: 100%;
    font-style: italic;
    justify-content: center;
    align-items: center;
  }
}
