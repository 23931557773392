@import '../../styles/main';

.notifications {
  display: flex;
  flex-direction: column;
  &__filter-controls {
    margin-bottom: 2.5rem;
  }
  &__mark-all-read-button {
    align-self: flex-end;
    margin-bottom: 1.25rem !important;
    &:disabled {
      color: $nepal;
      &:hover {
        color: $nepal;
        text-decoration: none;
      }
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__notification {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    padding-left: 3.625rem;
    border: 1px solid $geyser;
    background-color: #fff;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:not(:first-child) {
      border-top: 0;
    }
    &:first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
    &:hover {
      background-color: $alabaster;
    }
    &:focus {
      outline: none;
    }
    &-selected {
      &:not(:first-of-type) {
        margin-top: 1rem;
        border-top: 1px solid $geyser;
        transition: border-top 1ms;
      }
      + .expansion-container {
        border: 1px solid $geyser;
        border-top: none;
        + .table-container {
          border-top: 1px solid $geyser;
        }
        .expansion-child-wrapper {
          background-color: #fff;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0.5rem;
            background-image: linear-gradient(
              to top,
              rgba(103, 119, 143, 0),
              #67778f
            );
            opacity: 0.05;
          }
        }
      }
    }
  }
  &__notification-left {
    color: #000;
    font-weight: normal;
    opacity: 0.65;
    &-unread {
      font-weight: 500;
    }
  }
  &__notification-right {
    color: $gray-blue;
    font-size: 1rem;
    font-weight: 500;
  }
  &__notification-subject,
  &__notification-details {
    margin-bottom: 0.5rem;
  }
  &__read-indicator {
    @include notificationIndicator();
    &-read {
      background-color: $clear;
    }
  }
  &__notification-left,
  &__notification-right {
    display: flex;
    flex-direction: column;
  }
  &__notification-right {
    align-items: flex-end;
  }
  &__notification-details {
    text-transform: capitalize;
  }
  &__message-wrapper {
    display: flex;
    justify-content: center;
  }
  &__empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1.5rem;
    border: 1px solid $geyser;
    border-radius: 0.25rem;
    background-color: #fff;
    color: $gray-blue;
    > .notifications__empty-message {
      font-size: 1.125rem;
      font-weight: 400;
    }
    > .notifications__empty-sub-message {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }
  &__error {
    margin-bottom: 1rem !important;
    font-size: 0.8rem;
    color: red;
  }
  > .pill__container {
    text-transform: capitalize;
  }
}
