.monitor {
  .loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }

  .experiment-info-header {
    display: inline-flex;
    flex-direction: column;
  }
  h4 {
    .experiment-descriptor {
      color: gray;
      display: inline-flex;
      .activation-event-modal-button {
        margin-left: 5px;
        margin-top: -1px;
      }
    }
  }
  .activation-event-modal-list {
    text-align: left;
    margin-left: 120px;
  }

  .prism-link-out-box {
    display: flex;
    background-color: white;
    padding-top: 10px;
    padding-left: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    .prism-link-description {
      font-size: 15px;
      padding-top: 2px;
      font-weight: bolder;
    }
    .prism-link-button {
      padding-left: 10px;
      padding-top: 1px;
    }
  }

  .bar-chart-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 102%;
    margin-top: 40px;
    .checkboxes {
      align-self: flex-end;
      margin-right: 25px;
      .activated-devices-checkbox {
        margin-top: -20px;
        margin-bottom: -30px;
      }
      .activated-devices-icon {
        background-color: #0073d1;
      }
      .allocated-devices-icon {
        background-color: #003561;
      }
      .allocated-devices-checkbox {
        margin-bottom: -40px;
      }
    }
    .learn-page-link {
      /* hidden until we add this learn article */
      display: none;
      align-self: flex-end;
      margin-bottom: -42px;
      margin-right: 230px;
    }
  }
  .no-monitoring-data-container {
    position: relative;
    text-align: center;
    color: white;

    .bar-chart-empty {
      opacity: 0.4;
      filter: alpha(opacity=40); /* msie */
    }

    .no-monitoring-data-text {
      color: black;
      position: absolute;
      top: 25%;
      left: 8%;
    }
  }

  .bar-chart {
    padding: 30px;
    margin-top: 20px;
    background-color: white;
    margin-bottom: 20px;
  }

  .activated-devices-icon-legend {
    background-color: #0073d1;
    margin-right: 5px;
  }
  .allocated-devices-icon-legend {
    background-color: #003561;
    margin-right: 5px;
  }

  .line-graph {
    padding: 30px;
    margin-top: 20px;

    .variants {
      padding-top: 20px;
    }
    background-color: white;
  }

  .custom-tooltip {
    background-color: white;
    border-color: gray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    p {
      padding: 0px 10px 5px 10px;
    }
  }

  .gray-bar-explainer {
    color: gray;
  }
}
