@import '../../styles/main';

.clone-button {
  display: flex;
  align-items: center;
  &__button {
    color: $fun-green !important;
  }
  &__input {
    max-width: 100% !important;
  }
  &__dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    padding: 2rem 3rem;
    border-radius: 0.25rem;
    background: #fff;
  }
  &__dialog-header {
    margin-bottom: 2rem;
    color: $scorpion;
  }
  &__dialog-submit {
    margin-bottom: 1.5rem !important;
  }
}