.cancel-complete-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 44rem;
  padding: 3rem 1.5rem 1.5rem;
  border-radius: 0.25rem;
  background: #fff;
  &__title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  &__winner-variant {
    flex: 1;
    margin-bottom: 2rem;
    max-width: 100% !important;
  }
  &__final-outcome {
    flex: 1;
    height: 11rem;
    margin-bottom: 2rem;
  }

  &__button-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .kite-btn-primary {
    margin-bottom: 10px;
  }
}
