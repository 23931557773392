.end-experiment-btn {
  .kite-con-menu__button {
    display: flex;
    background-color: transparent;
    border: 1px solid #0073d1;
    color: #0073d1;
    align-items: center;
    justify-content: center;
    height: 42px;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: 500;
    line-height: 1.5;
    max-width: 100%;
    padding: 8px 20px;
    position: relative;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    vertical-align: middle;
    user-select: none;
  }
  .kite-con-menu__menu {
    margin-bottom: 10px;
    top: -370%;
  }
  &__delete-btn {
    color: #d6312b;
  }
  &__end-modal {
    width: 569px;
    max-height: 376px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 32px;
    background-color: #fff;
    .kite-radio__button {
      margin-bottom: 0px !important;
    }
    .kite-form-group {
      margin-bottom: 0px !important;
    }
  }
  &__header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__end-modal-btn-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__disabled-btn {
    color: gray;
  }
}